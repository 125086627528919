import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as data from './data';

import * as G from '../../styles/global'
import * as S from './styled'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


export const Menu = () => {

  const menuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [expanded, setExpanded] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [leaveTimeout, setLeaveTimeout] = useState(null);

  const handleMouseEnter = () => {
    if (leaveTimeout) {
      clearTimeout(leaveTimeout);
      setLeaveTimeout(null);
    }
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    setLeaveTimeout(setTimeout(() => {
      setExpanded(false);
      setOpenSubmenu(null);
    }, 1200
    ));
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const isActiveParent = (item) => {
    if (item.subItems) {
      return item.subItems.some(subItem => location.pathname === subItem.path);
    }
    return location.pathname === item.path;
  };



  const handleItemClick = (item, index) => {
    if (item.subItems) {
      setOpenSubmenu(openSubmenu === index ? null : index);
      console.log(menuRef.current)
      setTimeout(() => {
        const lastSubItem = menuRef.current; 
        if (lastSubItem) {
          lastSubItem.scrollTo({
            top: 250,
            behavior: 'smooth'
          })
        }
      }, 100);
    } else {
      navigate(item.path);
    }
  };



  return (
    <div style={{zIndex: '5'}}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    >
 
    <div 
      style={{backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column', position: 'absolute', marginTop: '5px', top: '2px', left: '10px', cursor: 'pointer'}}>
      <G.Image 
      style={{width: '70px', height: '70px'}} src='./logo.png'/>
      {!expanded && (
        <KeyboardArrowDownIcon style={{fontSize: 50, color: '#aaa', marginTop: '-15px'}} />
      )}
     </div>
  <S.MenuContainer  
  expanded={expanded}>
	<S.MenuItemGroup style={{ }}  ref={menuRef}>
      {data.menuItems.map((item, index) => (
        <React.Fragment key={index}>
          <S.MenuItem 
            // onMouseEnter={handleMenuItemHover}
            onClick={() => {
              handleItemClick(item, index);
            }}
            active={isActiveParent(item)}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                {item.icon}
                {item.title}
              </div>
                <>
                {item.subItems && (
                  openSubmenu === index ? 
                    <KeyboardArrowUpIcon /> : 
                    <KeyboardArrowDownIcon />
                )}
              </>
          </S.MenuItem>
          {item.subItems && openSubmenu === index && (
            item.subItems.map((subItem, subIndex) => (
              <S.SubMenuItem 
                key={subIndex}
                onClick={() => navigate(subItem.path)}
                active={isActive(subItem.path)}
              >
                {subItem.title}
              </S.SubMenuItem>
            ))
          )}
        </React.Fragment>
      ))}
	</S.MenuItemGroup>
  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '10px', width: '90%'}}>
  {data.optionsItems.map((item, index) => (
    <React.Fragment key={index}>
      <S.OptionsItem    
        onClick={() => handleItemClick(item, index)}
        active={isActiveParent(item)}

      >
        {item.icon}
        {expanded && (
          <>
            <span style={{flex: 1}}>{item.title}</span>
            {item.subItems && (
              openSubmenu === index ? 
                <KeyboardArrowUpIcon /> : 
                <KeyboardArrowDownIcon />
            )}
          </>
        )}
      </S.OptionsItem>
      {expanded && item.subItems && openSubmenu === index && (
        item.subItems.map((subItem, subIndex) => (
          <S.SubOptionsItem 
            key={subIndex}
            onClick={() => navigate(subItem.path)}
            active={isActive(subItem.path)}
          >
            {subItem.title}
          </S.SubOptionsItem>
        ))
      )}
    </React.Fragment>
    ))}

  </div>
    </S.MenuContainer>
  </div>
  );
};
  