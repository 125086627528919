import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';
import moment from 'moment'

import * as C from './styled'
import * as G from '../../../styles/global'
import * as M from '../../../styles/modal'

import Api from '../../../services/api'
import FinancialApi from '../../../services/financialApi'
import { UserContext } from '../../../contexts/UserContext';

import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import ConstructionIcon from '@mui/icons-material/Construction';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import BallotIcon from '@mui/icons-material/Ballot';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { border, borderLeft } from '@mui/system';





export const Proposal = () => {

  const navigate = useNavigate();
  const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

  const { state: user } = useContext(UserContext)

  const [state, setState] = useState(true)
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [alertError, setAlertError] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  const [services, setServices] = useState([])
  const [entityType, setEntityType] = useState([])
  const [taxpayerType, setTaxpayerType] = useState([])
  const [selectedEntityType, setSelectedEntityType] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false) 
  const [empty, setEmpty] = useState(null)
  const [stateErrors, setStateErros] = useState('');

  const [selectedServicesList, setSelectedServicesList] = useState([])
  const [selectedServicesQuantity, setSelectedServicesQuantity] = useState(1)
  const [selectedServices, setSelectedServices] = useState('')



  /// DATATABLE STATES /////////////////////////////////////////////////////////

  const [filteredList, setFilteredList] = useState([])
  const [order, setOrder] = useState('')
  const [activeOrder, setActiveOrder] = useState('')


  
/// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    let newArray = list.filter((data) => {
      return data.entity_type_acronym.toLowerCase().search(value) !== -1 
        || data.trade_name.toLowerCase().search(value) !== -1 
        || data.address_city.toLowerCase().search(value) !== -1 
        || data.address_state_acronym.toLowerCase().search(value) !== -1 
        || data.address_country.toLowerCase().search(value) !== -1 
        || data.contact_phone.toLowerCase().search(value) !== -1
    })
    setFilteredList(newArray);
  }

  const OrderClick = (field) => {
    setActiveOrder(field)
    let result = filteredList.sort(function(a, b) {
      if (order === 'asc') {
        return a[field] < b[field] ? -1 : true
      } else {
        return a[field] > b[field] ? -1 : true
      }
    })
    order === 'asc' ? setOrder('desc') : setOrder('asc')
    setFilteredList(result)
  }

  
  /// MASKS ////////////////////////////////////////////////////////////////////

  const [cnpjNumber, setCnpjNumber] = useState('')
  const onChangeCnpj = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['999.999.999/9999-99']);
    setCnpjNumber(unMask(maskedValue));
  }

  const [cpfNumber, setCpfNumber] = useState('')
  const onChangeCpf = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['999.999.999-99']);
    setCpfNumber(unMask(maskedValue));
  }

  const [rgNumber, setRgNumber] = useState('')
  const onChangeRg = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99.999.999-9']);
    setRgNumber(unMask(maskedValue));
  }

  const [cepNumber, setCepNumber] = useState('')
  const onChangeCep = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99999-999']);
    setCepNumber(unMask(maskedValue));
  }

  const [phoneNumber, setPhoneNumber] = useState('')
  const onChangePhone = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9999-9999']);
    setPhoneNumber(unMask(maskedValue));
  }

  const [cellNumber, setCellNumber] = useState('')
  const onChangeCell = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9 9999-9999']);
    setCellNumber(unMask(maskedValue));
  }



  /// FUNCTIONS ////////////////////////////////////////////////////////////////
  const handleAddService = () => {
    if (selectedServices && selectedServicesQuantity) {
      setSelectedServicesList([
        ...selectedServicesList,
        {
          name: selectedServices,
          quantity: selectedServicesQuantity,
          value: services.find(service => service.name === selectedServices).service_value
        }
      ]);
    }
  }


  const loadBases = async () => {
    let res1 = await FinancialApi.getEntityType();
    setEntityType(res1);
    let res2 = await FinancialApi.getTaxpayerType();
    setTaxpayerType(res2);
    let res3 = await Api.getServices('1');
    setServices(res3);
  }
 

  const GetData = async () => {
    setEmpty(false)
    setLoading(true)
    let res = await FinancialApi.getProposal();
    if (res.length > 0) {
      setEmpty(false)
      setList(res)
      setFilteredList(res)
    } else {
      setEmpty(true)
    }
    setLoading(false)
  }


  const onSubmit = async (data) => {
    data["cnpj"] = cnpjNumber
    data["cpf"] = cpfNumber
    data["rg"] = rgNumber
    data["address_zip_code"] = cepNumber
    data["contact_phone"] = phoneNumber
    data["contact_cell_phone"] = cellNumber

     switch (modalType) {
      case 1:
        const userAdd = ({
          created_by: user.id,
          changed_by: user.id
        })
        const add = ({...data, ...userAdd})
        let res1 = await FinancialApi.postOther(add)
        break;

      case 2:
        const userUpd = ({
          changed_by: user.id
        })
        const upd = ({...data, ...userUpd})
        let res2 = await FinancialApi.postOther(upd)
        break;

      default:
        break;
    }

    CloseModalClick()

  };


  const DelOther = async (id) => {
    let res = await FinancialApi.delOther(id);
    GetData();
  }


  const ActOther = async (id, estado) => {
    const data = ({
      id: id, 
      state: estado, 
      changed_by: user.id
    })
    let res = await FinancialApi.postOther(data);
    GetData();
  }


  const AddClick = () => {
    setModalType(1)
    loadBases()
    setModal(true)
  }  


  const EditClick = (key) => {
    setSelectedKey([key])

    setCnpjNumber(filteredList[key].cnpj);
    setCpfNumber(filteredList[key].cpf);
    setRgNumber(filteredList[key].rg);
    setCepNumber(filteredList[key].address_zip_code);
    setPhoneNumber(filteredList[key].contact_phone);
    setCellNumber(filteredList[key].contact_cell_phone);

    setModalType(2)
    loadBases()
    setModal(true)
  }


  const DelClick = async (key) => {
    setSelectedKey([key])
    setModalType(3)
    setModal(true)
  }


  const ActClick = (key) => {
    setSelectedKey([key])
    setModalType(4)
    setModal(true)
  }


  const CloseModalClick = () => {
    setLoading(true)
    setSelectedKey('')
    setAlertError('')
    GetData()
    setCnpjNumber('')
    setCpfNumber('')
    setRgNumber('')
    setCepNumber('')
    setPhoneNumber('')
    setCellNumber('')
    setStateErros(false)
    setModal(false)
    setSelectedEntityType('')
    setModalType('')
    reset()
    setLoading(false)
  }


  const CloseModalClick2 = () => {
    setLoading(true)
    setSelectedKey('')
    setAlertError('')
    setCnpjNumber('')
    setCpfNumber('')
    setRgNumber('')
    setCepNumber('')
    setPhoneNumber('')
    setCellNumber('')
    setStateErros(false)
    setModal(false)
    setSelectedEntityType('')
    setModalType('')
    reset()
    setLoading(false)
  }





/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      CloseModalClick();
    }
  }



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{ 
    GetData();
  }, [state]);



/// CONSOLE LOG ////////////////////////////////////////////////////////////////

console.log(list)

  



////////////////////////////////////////////////////////////////////////////////





  return (
    <G.Container>

      <G.Header>
        <G.HeaderTitleArea style={{ width: '240px' }}>
          <BallotIcon style={{ fontSize: '32px', margin: '0px 0px 0px 0px' }} />
          <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 8px', border: '0.04rem solid #333', borderRadius: '4px' }} />
          <G.MainTitle style={{ fontSize: 'var(--font-size-title)' }}>Criar Orçamento</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '320px' }}>
          
        </G.HeaderTitleArea>
        <G.SearchArea style={{gap: '20px'}}>
        <G.SwitchArea>
          <SearchIcon />
          <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
        </G.SwitchArea>     
        <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
          <AddIcon fontSize='small'/>
          {'Adicionar'}
        </G.Button>
        </G.SearchArea>
      </G.Header>

      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{ justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column' }}>
            <ErrorOutlineRoundedIcon style={{ fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{ fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && filteredList.length > 0)  && 
          <>
            <G.Box>

              <G.TableHeader>
                <G.OptionsArea style={{ padding: '0px', gap: '20px', justifyContent: 'center' }}>
                  <G.FieldArea size={'50px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('id')}><G.DistacInfoText>ID</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'entity_type_id') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'250px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('company_name')}><G.DistacInfoText>Nome</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'company_name') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'70px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('address_city')}><G.DistacInfoText>Criado</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'address_city') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'70px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('address_city')}><G.DistacInfoText>Vencimento</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'address_city') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'120px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('company_name')}><G.DistacInfoText>Propritario</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'company_name') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'70px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('address_state_acronym')}><G.DistacInfoText>Estado</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'address_state_acronym') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'110px'} style={{ justifyContent: 'center' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>

              {(loading === true && filteredList.length === 0 ) &&  
                <G.Loading2 src={"/loading.svg"} />
              }

              {(loading === false && filteredList.length > 0) &&
                <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                  {/* {(loading === false && filteredList.length === 0) && 
                    <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                      *** Não existem itens a exibir. ***
                    </G.Text>
                  } */}
                  <>
                    {filteredList.map((item, key) => (
                      <G.TableArea key={key} style={{ gap: '20px', justifyContent: 'center'}}>
                       <G.FieldArea size={'50px'} style={{justifyContent: 'center', padding: '0px'}}>
                          <div style={{display: 'flex', justifyContent: 'center', width: '40px',fontSize: '11px', border: '1px solid #555', fontWeight: 'bold', padding: '7px', borderRadius: '5px'}}>{item.id}</div>
                       </G.FieldArea>                       
                        <G.FieldArea style={{justifyContent: 'start'}} size={'250px'}><G.Text>{item.name}</G.Text></G.FieldArea>
                        <G.FieldArea size={'70px'}><G.Text>{new Date(item.created_time).toLocaleDateString('pt-BR')}</G.Text></G.FieldArea>
                        <G.FieldArea size={'70px'}><G.Text>{new Date(item.due_date).toLocaleDateString('pt-BR')}</G.Text></G.FieldArea>
                        <G.FieldArea style={{justifyContent: 'center'}} size={'120px'}><G.Text>{'Fabricio Freitas'}</G.Text></G.FieldArea>
                        <G.FieldArea size={'100px'} style={{ padding: '0px 4px' }}>
                          <C.ContactTypeIcon style={{ borderRadius: '0px', borderLeft: item.proposal_state_id === 2 ? '3px solid #1E90FF' : item.proposal_state_id === 1 ? '3px solid #2E8B57' : item.proposal_state_id === 3 ? '3px solid #E95839' : item.proposal_state_id === 4 ? '3px solid #FFB400' : '#555', height: 'auto', flex: 'flex', flexDirection: 'column', width: '100px', padding: '6px', fontSize: '11px', color: '#000', backgroundColor: '#F5f5f5' }}>
                            {item.state}
                            <text style={{fontWeight: 'normal', color: '#555'}}>
                            04/02/1978
                              </text>
                          </C.ContactTypeIcon>
                        </G.FieldArea>
                        <G.FieldArea size={'110px'} style={{ justifyContent: 'flex-end' }}>
                          <G.IconButton onClick={() => EditClick(key) + setSelectedEntityType((filteredList[key].entity_type_id).toString())} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}>
                            <EditIcon fontSize="small" />
                          </G.IconButton>
                          <G.IconButton onClick={() => ActClick(key)} style={{ backgroundColor: item.state === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>
                            {item.state === 0 ? <CheckIcon fontSize="small"/> : item.state === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}
                          </G.IconButton>
                          <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}>
                            <DeleteIcon fontSize="small"/>
                          </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                </G.TableBody>
              }
            </G.Box>
          </>
        }
      </G.Body>

      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>

      {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ justifyContent: 'center' }}>
            <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'start', marginTop: '5px'}}>

              {(modalType === 2 || modalType === 3) && 
                <G.FormInput style={{ width: '80px'}} type="hidden" readOnly={true} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].id} {...register("id")} />
              }

              {(modalType === 1 || modalType === 2) &&
                <G.BoxBodyScroll style={{ display: 'flex', margin: '0px 0px', flexWrap: 'wrap',  justifyContent: 'start' }}>

                  <G.FormInputArea style={{ width: '680px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Cliente
                    </G.FormInputLabel>
                    <G.FormSelect 
                      {...register("entity_type_id", { required: true })}
                      onChange={(e) => setSelectedEntityType(e.target.value)}
                    >
                      <option autoFocus disabled selected value={modalType === 1 ? '' : filteredList[selectedKey].entity_type_id}>
                        {modalType === 1 ? 'Selecione' : filteredList[selectedKey].entity_type_name}
                      </option>
                      {entityType.map(i => (
                        <option key={i.id} value={i.id}>{i.name}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '250px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Vencimento
                    </G.FormInputLabel>
                    <G.FormInput type="date" defaultValue={modalType === 1 ? '' :  moment(filteredList[selectedKey].due_date).format('YYYY-MM-DD')} {...register("due_date")} />
                  </G.FormInputArea>
                  <div style={{ display: 'flex', flexDirection: 'column', width:'95%', alignItems: 'center', marginTop: '10px', marginBottom: '10px', padding: '10px', justifyContent: 'center', border: '1px solid #ccc', borderRadius: '5px' }}>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: '20px'}}>
                    <G.FormInputArea style={{ width: '550px' }}>
                      <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                        Serviço
                      </G.FormInputLabel>
                      <G.FormSelect 
                        onChange={(e) => setSelectedServices(e.target.value)}
                      >
                        <option autoFocus disabled selected value={''}>
                          {'Selecione'}
                        </option>
                        {services.map(i => (
                          <option key={i.id} value={i.name}>{i.name}</option>
                        ))}
                      </G.FormSelect>
                    </G.FormInputArea>
                    <G.FormInputArea style={{ width: '150px' }}>
                      <G.FormInputLabel>Quantidade</G.FormInputLabel>
                      <G.FormInput type="number" onChange={(e) => setSelectedServicesQuantity(e.target.value)} />
                    </G.FormInputArea>
                    <G.Button onClick={() => handleAddService()} style={{ backgroundColor: '#2E8B57', width: '100px' }}>
                      Adicionar
                    </G.Button>
                    </div>               
                    <div style={{width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>               
                    <table style={{ width: '90%', borderCollapse: 'collapse' }}>
                      <tbody>
                        {selectedServicesList.map((service, index) => (
                          <tr key={index}>
                            <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{service.name}</td>
                            <td style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>{service.quantity}</td>
                            <td style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>{service.value}</td>
                            <td style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid #ddd' }}>
                              <G.IconButton  style={{ backgroundColor: '#E95839' }}   onClick={() => {
                                  const newList = selectedServicesList.filter((_, i) => i !== index)
                                  setSelectedServicesList(newList)
                                }}>
                                <DeleteIcon 
                                style={{ cursor: 'pointer', color: '#fff' }}              
                                />
                              </G.IconButton>
      
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  </div>
                  {/* <G.FormInputArea style={{ width: '300px' }}>
                    <G.FormInputLabel>
                      Responsável
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_responsavel} {...register("fncdr_responsavel", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea> */}

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '16px 0px 16px -8px' }}>
                    {(Object.keys(errors).length > 0 || stateErrors) &&  
                      <div role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '92%', padding: '7px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                        <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                        <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{'TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS!'}</div>
                        <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                      </div>
                    }
                  </div>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '10px', marginBottom: '10px' }}>
                    <G.FormInputLabel style={{ width: '936px', justifyContent: 'flex-start', border: 'none', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                      Observação
                    </G.FormInputLabel>
                    <G.TextArea 
                      {...register("note", { setValueAs: v => v === '' ? null : v })} 
                      style={{ width: '916px', height: '150px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                    >
                      {modalType === 1 ? '' :  filteredList[selectedKey].note}
                    </G.TextArea>
                  </G.FieldArea>

                </G.BoxBodyScroll>
              }
            </M.ModalBody>
            
            

            {(modalType === 3) && 
              <>
                <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                  <G.Text style={{ margin: '10px 0px' }}>
                    Deseja excluir o Fornecedor:
                  </G.Text>
                  <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                    <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                      {filteredList[selectedKey].company_name}
                    </G.FormInputLabel>
                  </G.FormInputArea>
                </M.ModalBody>
              </>
            }

            {(modalType === 4) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja {filteredList[selectedKey].state === 0 ? 'ativar' : 'desativar'} o Fornecedor:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].company_name}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {alertError !== '' &&
                <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
              }
              
              {(modalType === 1 || modalType === 2) &&
                <>
                  <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                </>
              }

              {modalType === 3 && 
                <>
                  <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelOther(filteredList[selectedKey].id) + CloseModalClick()}>Sim</G.Button>
                </>
              }
              {modalType === 4 && 
                <>
                  <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActOther(filteredList[selectedKey].id, filteredList[selectedKey].state === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                </>
              }

            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }
    </G.Container>
  );
}

