import React, {useState, useEffect, useContext} from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import { Menu } from '../../components/Menu/index.js'
import { MainHeader } from '../../container/Header';
import { SideBar } from '../../container/SideBar';
import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import * as C from './styled'


export const Layout = () => {

  const navigate = useNavigate()
  const { dispatch } = useContext(UserContext);

  const [loading, setloading] = useState(true)



  useEffect(()=>{
      const checkLogin = async () => {
        const res = await localStorage.getItem('SessionID')
         if(res){
            let res2 = await Api.checkToken()
              if(res2.message === 'jwt expired'){
                navigate('/Login')
              } else {
                dispatch({
                    type: 'setUser',
                    payload:{
                      id: res2[0].id,
                      user: res2[0].login,
                      first_name: res2[0].first_name,
                      last_name: res2[0].last_name,
                      email: res2[0].email,
                      photo: res2[0].photo
                    },
                });
                // navigate('/Dashboard')
              }


        } else {
          navigate('/Login')
        }
      }
      checkLogin()

    }, [])

    return (
      <C.Container>
        <Menu/>
            <C.Body>
              {/* <C.Header>
                <MainHeader/>
              </C.Header> */}
              <C.PageBody>
                <Outlet />
              </C.PageBody>
            </C.Body>
       </C.Container>
    );
}
