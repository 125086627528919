
const API = process.env.REACT_APP_BACKEND;

// const token = localStorage.getItem('SessionID')

export default {

checkToken: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/session?token=${token}`)
    const res = await req.json();
    return res;
},

login: async (UserLogin, Password) => {
    const req = await fetch(`${API}/auth`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'login' : UserLogin,
            'password': Password
        })
    });
    const res = await req.json();
    return res;
},




/////////////////////////////////////////

// tokenInter: async () => {
//     const req = await fetch(`${API}/inter?nome=inter`)
//     const res = await req.json();
//     return res;
// },


getMail: async (login) => {
    const req = await fetch(`${API}/renew?login=${login}`)
    const res = await req.json();
    return res;
},

renew: async (login, pw) => {
    const req = await fetch(`${API}/renew`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            {
                "login": login,
                "pw": pw
            }
        )
    });
    const res = await req.json();
    return res;
},



sendMail: async (mailDest, mailBody) => {
    const req = await fetch(`${API}/sendMail`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            {
                "dest": mailDest,
                "subject": 'Token para criação de nova senha',
                "body": mailBody,

            }
        )
    });
    const res = await req.json();
    return res;
},

postFileArticle: async (file, article) => {
    
    let formData = new FormData()
    formData.append('file', file)
    formData.append('article_id', article)

    const req = await fetch(`${API}/articleFile`, {
         method: 'POST',
         headers: {
             Accept: 'multipart/form-data',
         },
         body: formData
     })
     return req;
},


/// - ARQUIVOS - ///////////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

uploadFile: async (file) => {
    const token = await localStorage.getItem('SessionID');
    let formData = new FormData()
    formData.append('file', file)
    const req = await fetch(`${API}/upload?token=${token}`, {
        method: 'POST',
        headers: {
            Accept: 'multipart/form-data',
        },
        body: formData
    })
    return req;
},


deleteFile: async (file) => {
    const token = await localStorage.getItem('SessionID');
    const req = await fetch(`${API}/delete?file=${file}&token=${token}`, {
        method: 'DELETE'
    });
    const res = await req.json();        
    return res;
},

getCustomerData: async (data) => {
    const req = await fetch(`${API}/cnpj?cnpj=${data}`);
    const res = await req.json();
    return res;
    
},

/// ----------------------------------------------------------------------------



//////////////////////////////////////////////////////////////

// deleteFile: async (file) => {

//     const req = await fetch(`${API}/delete?file=${file}`, {
//         method: 'DELETE'
//     });
//     const res = await req.json();        
//     return res;

// },

///Tickets V2////////////////////////////////////////////////////// 

postTicket: async (postData) => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/ticket?token=${token}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
           postData
        ),
    })
    const res = await req.json();
    return res;
},


getTicket: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/ticket?token=${token}`);
    const res = await req.json();
    return res;
    
},

getTickets: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/tickets?token=${token}`);
    const res = await req.json();
    return res;
},


postArticle: async (postData) => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/article?token=${token}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
           postData
        )
    });
    const res = await req.json();
    return res;
},


getArticle: async (id) => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/article?token=${token}&id=${id}`);
    const res = await req.json();
    return res;
    
},


getArticleFile: async (id) => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/articleFile?token=${token}&id=${id}`);
    const res = await req.json();
    return res;
    
},


getUser: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/user?token=${token}`)
    const res = await req.json();
    return res;
},


postUser: async (postData) => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/user?token=${token}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            postData
        )
    });
    const res = await req.json();
    return res;
},


getType: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/type?token=${token}`)
    const res = await req.json();
    return res;
},


getSla: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/sla?token=${token}`)
    const res = await req.json();
    return res;
},


getService: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/service?token=${token}`)
    const res = await req.json();
    return res;
},


getQueue: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/queue?token=${token}`)
    const res = await req.json();
    return res;
},

getState: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/state?token=${token}`)
    const res = await req.json();
    return res;
},

getPriority: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/priority?token=${token}`)
    const res = await req.json();
    return res;
},

getTemplate: async () => {
    const token = localStorage.getItem('SessionID')
    const req = await fetch(`${API}/template?token=${token}`)
    const res = await req.json();
    return res;
},


/// ----------------------------------------------------------------------------

getServices: async (type) => {
    const token = await localStorage.getItem('SessionID');
    const req = await fetch(`${API}/services?token=${token}&type=${type}`)
    const res = await req.json();
    return res;
  },
  
  
  postServices: async (postData) => {
    const token = await localStorage.getItem('SessionID');
    const req = await fetch(`${API}/services?token=${token}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(
      postData
    )
    });
    const res = await req.json();
    return res;
  },
  
  
  delServices: async (id) => {
    const token = await localStorage.getItem('SessionID');
    const req = await fetch(`${API}/services?id=${id}&token=${token}`, {
      method: 'DELETE'
    });
    const res = await req.json();
    return res;
  },
  

  /// - FIN - SERVICE TYPES - ////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

    getServiceType: async () => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${API}/serviceType?token=${token}`)
        const res = await req.json();
        return res;
    },
  
  
  postServiceType: async (postData) => {
    const token = await localStorage.getItem('SessionID');
    const req = await fetch(`${API}/serviceType?token=${token}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(
      postData
    )
    });
    const res = await req.json();
    return res;
  },
  
  
  delServiceType: async (id) => {
    const token = await localStorage.getItem('SessionID');
    const req = await fetch(`${API}/serviceType?id=${id}&token=${token}`, {
      method: 'DELETE'
    });
    const res = await req.json();
    return res;
  },
  
  
  /// ----------------------------------------------------------------------------
  
  
  /// ----------------------------------------------------------------------------

  getCalendar: async () => {
    const token = await localStorage.getItem('SessionID');
    const req = await fetch(`${API}/calendar?token=${token}`)
    const res = await req.json();
    return res;
  },

//////////////////////////////////////////////////////////////

  /// ----------------------------------------------------------------------------

  getCalendarAppointments: async (id) => {
    const token = await localStorage.getItem('SessionID');
    const req = await fetch(`${API}/calendarAppointments?token=${token}&calendar_id=${id}`)
    const res = await req.json();
    return res;
  },

  postCalendarAppointments: async (postData) => {
    const token = await localStorage.getItem('SessionID');
    const req = await fetch(`${API}/calendarAppointments?token=${token}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        postData
      )
    });
    const res = await req.json();
    return res;
  },

  delCalendarAppointments: async (id) => {
    const token = await localStorage.getItem('SessionID');
    const req = await fetch(`${API}/calendarAppointments?id=${id}&token=${token}`, {
      method: 'DELETE'
    });
    const res = await req.json();
    return res;
  },

//////////////////////////////////////////////////////////////


// addIntegracao: async (postData) => {
//     const req = await fetch(`${API}/integracao`, {
//         method: 'POST',
//         headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(
//             postData
//         )
//     });
//     const res = await req.json();
//     return res;
// },

// getIntegracao: async () => {
//     const req = await fetch(`${API}/integracao`)
//     const res = await req.json();
//     return res;
// },

// delIntegracao: async (id) => {
//     const req = await fetch(`${API}/integracao?id=${id}`, {
//         method: 'DELETE'
//     });
//     const res = await req.json();        
//     return res;
// },

// //////////////////////////n  Dashboard

// getProcessAlert: async () => {
//     const req = await fetch(`${API}/processAlert`)
//     const res = await req.json();
//     return res;
// },


// //////////////////////////////////////////

//     getCardProcess: async (types, state) => {
//         console.log(state)
//         const req = await fetch(`${API}/cardProcess?${types}&${state}`);
//         const res = await req.json();
//         return res
//     },

//     getArchProcess: async (year) => {
//         const req = await fetch(`${API}/process?year=${year}`);
//         const ticket = await req.json();
//         return ticket
//     },

//     getArchProcessCriteria: async (item, value) => {
//         const req = await fetch(`${API}/process?item=${item}&value=${value}`);
//         const ticket = await req.json();
//         return ticket
//     },


//     getFases: async (codProcess) => {
//         const req = await fetch(`${API}/fases?idProcesso=${codProcess}`)
//         const fasesGet = await req.json();
//         return fasesGet;
//     },

//     getComentarios: async (codFase) => {
//         const req = await fetch(`${API}/comentarios?idFase=${codFase}`)
//         const comentariosGet = await req.json();
//         return comentariosGet;
//     },





// /// TIPOS-FASE /////////////////////////////////////////////////////////////////

//     getTiposFase: async () => {
//         const req = await fetch(`${API}/tiposFase`)
//         const res = await req.json();
//         return res;
//     },

//     upPhaseTypes: async (postData) => {
//         const req = await fetch(`${API}/upTiposFase`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addPhaseTypes: async (postData) => {
//         const req = await fetch(`${API}/addTiposFase`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delPhaseTypes: async (id) => {
//         const req = await fetch(`${API}/delTiposFase?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },



// /// TIPOS-ATIVIDADE /////////////////////////////////////////////////////////////////

//     getActivitieTypes: async () => {
//         const req = await fetch(`${API}/tiposAtividade`)
//         const res = await req.json();
//         return res;
//     },

//     upActivitieTypes: async (postData) => {
//         const req = await fetch(`${API}/upTiposAtividade`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addActivitieTypes: async (postData) => {
//         const req = await fetch(`${API}/addTiposAtividade`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delActivitieTypes: async (id) => {
//         const req = await fetch(`${API}/delTiposAtividade?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },



// /// TIPOS-USUARIOS /////////////////////////////////////////////////////////////

//     getUserTypes: async () => {
//         const req = await fetch(`${API}/userTypes`)
//         const res = await req.json();
//         return res;
//     },

//     upUserTypes: async (postData) => {
//         const req = await fetch(`${API}/upUserTypes`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addUserTypes: async (postData) => {
//         const req = await fetch(`${API}/addUserTypes`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delUserTypes: async (id) => {
//         const req = await fetch(`${API}/delUserTypes?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },





// /// USUARIOS ///////////////////////////////////////////////////////////////////

//     getUsers: async () => {
//         const req = await fetch(`${API}/users`)
//         const res = await req.json();
//         return res;
//     },

    upUsers: async (postData) => {
        const req = await fetch(`${API}/user`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                postData
            )
        });
        const res = await req.json();
        return res;
    },
    
//     addUsers: async (postData) => {
//         const req = await fetch(`${API}/addUsers`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },
    
//     delUsers: async (id) => {
//         const req = await fetch(`${API}/delUsers?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res ;
//     },




// /// ADVOGADOS //////////////////////////////////////////////////////////////////

//     getLawyers: async () => {
//         const req = await fetch(`${API}/adv`)
//         const res = await req.json();
//         return res;
//     },

//     upLawyers: async (postData) => {
//         const req = await fetch(`${API}/upAdv`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },
    
//     addLawyers: async (postData) => {
//         const req = await fetch(`${API}/addAdv`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },
    
//     delLawyers: async (id) => {
//         const req = await fetch(`${API}/delAdv?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res ;
//     },




// /// EMPRESAS ///////////////////////////////////////////////////////////////////

//     getCompanies: async () => {
//         const req = await fetch(`${API}/emp`)
//         const res = await req.json();
//         return res;
//     },

//     upCompanies: async (postData) => {
//         const req = await fetch(`${API}/upEmp`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },
    
//     addCompanies: async (postData) => {
//         const req = await fetch(`${API}/addEmp`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },
    
//     delCompanies: async (id) => {
//         const req = await fetch(`${API}/delEmp?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res ;
//     },





// /// RECEBIMENTOS ///////////////////////////////////////////////////////////////

//     getReceipts: async (startDate, endDate) => {
//         // console.log(startDate, endDate)
//         const req = await fetch(`${API}/fin_recebimentos_data?dataInicial=${startDate}&dataFinal=${endDate}`);
//         const res = await req.json();
//         return res
//     },

//     upReceipts: async (postData) => {
//         const req = await fetch(`${API}/upFin_recebimentos`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addReceipts: async (postData) => {
//         const req = await fetch(`${API}/addFin_recebimentos`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delReceipts: async (id) => {
//         const req = await fetch(`${API}/delFin_recebimentos?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },





// /// PAGAMENTOS /////////////////////////////////////////////////////////////////

//     getPayments: async (startDate, endDate) => {
//         // console.log(startDate, endDate)
//         const req = await fetch(`${API}/fin_pagamentos_data?dataInicial=${startDate}&dataFinal=${endDate}`);
//         const res = await req.json();
//         return res
//     },

//     upPayments: async (postData) => {
//         const req = await fetch(`${API}/upFin_pagamentos`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addPayments: async (postData) => {
//         const req = await fetch(`${API}/addFin_pagamentos`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delPayments: async (id) => {
//         const req = await fetch(`${API}/delFin_pagamentos?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },






// /// BANCOS /////////////////////////////////////////////////////////////////////

//     getBanks: async () => {
//         const req = await fetch(`${API}/fin_bancos`)
//         const res = await req.json();
//         return res;
//     },

//     upBanks: async (postData) => {
//         const req = await fetch(`${API}/upFin_bancos`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addBanks: async (postData) => {
//         const req = await fetch(`${API}/addFin_bancos`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delBanks: async (id) => {
//         const req = await fetch(`${API}/delFin_bancos?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();        
//         return res;
//     },




// /// TIPOS-CONTA ////////////////////////////////////////////////////////////////

//     getAccountTypes: async () => {
//         const req = await fetch(`${API}/fin_tipos_conta`)
//         const res = await req.json();
//         return res;
//     },

//     upAccountTypes: async (postData) => {
//         const req = await fetch(`${API}/upFin_tipos_conta`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addAccountTypes: async (postData) => {
//         const req = await fetch(`${API}/addFin_tipos_conta`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delAccountTypes: async (id) => {
//         const req = await fetch(`${API}/delFin_tipos_conta?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();        
//         return res;
//     },





// /// CONTAS /////////////////////////////////////////////////////////////////////

//     getAccounts: async () => {
//         const req = await fetch(`${API}/fin_contas`)
//         const res = await req.json();
//         return res;
//     },

//     upAccounts: async (postData) => {
//         const req = await fetch(`${API}/upFin_contas`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addAccounts: async (postData) => {
//         const req = await fetch(`${API}/addFin_contas`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delAccounts: async (id) => {
//         const req = await fetch(`${API}/delFin_contas?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();        
//         return res;
//     },




// /// FORMAS-PAGAMENTO ///////////////////////////////////////////////////////////

//     getPaymentMethods: async () => {
//         const req = await fetch(`${API}/fin_formas_pagamento`)
//         const res = await req.json();
//         return res;
//     },

//     upPaymentMethods: async (postData) => {
//         const req = await fetch(`${API}/upFin_formas_pagamento`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addPaymentMethods: async (postData) => {
//         const req = await fetch(`${API}/addFin_formas_pagamento`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delPaymentMethods: async (id) => {
//         const req = await fetch(`${API}/delFin_formas_pagamento?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },




// /// ESTADOS-LANCAMENTO /////////////////////////////////////////////////////////

//     getPostingStatus: async () => {
//         const req = await fetch(`${API}/fin_estados_lancamento`)
//         const res = await req.json();
//         return res;
//     },

//     upPostingStatus: async (postData) => {
//         const req = await fetch(`${API}/upFin_estados_lancamento`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addPostingStatus: async (postData) => {
//         const req = await fetch(`${API}/addFin_estados_lancamento`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delPostingStatus: async (id) => {
//         const req = await fetch(`${API}/delFin_estados_lancamento?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },




// /// CATEGORIAS-RECEBIMENTO /////////////////////////////////////////////////////

//     getReceiptCategories: async () => {
//         const req = await fetch(`${API}/fin_categorias_recebimento`)
//         const res = await req.json();
//         return res;
//     },

//     upReceiptCategories: async (postData) => {
//         const req = await fetch(`${API}/upFin_categorias_recebimento`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addReceiptCategories: async (postData) => {
//         const req = await fetch(`${API}/addFin_categorias_recebimento`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delReceiptCategories: async (id) => {
//         const req = await fetch(`${API}/delFin_categorias_recebimento?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },




// /// CATEGORIAS-PAGAMENTO ///////////////////////////////////////////////////////

//     getPaymentCategories: async () => {
//         const req = await fetch(`${API}/fin_categorias_pagamento`)
//         const res = await req.json();
//         return res;
//     },

//     upPaymentCategories: async (postData) => {
//         const req = await fetch(`${API}/upFin_categorias_pagamento`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addPaymentCategories: async (postData) => {
//         const req = await fetch(`${API}/addFin_categorias_pagamento`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delPaymentCategories: async (id) => {
//         const req = await fetch(`${API}/delFin_categorias_pagamento?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },




// /// PLANO-CONTAS ///////////////////////////////////////////////////////////////

//     getAccountsPlan: async () => {
//         const req = await fetch(`${API}/fin_plano_contas`)
//         const res = await req.json();
//         return res;
//     },

//     upAccountsPlan: async (postData) => {
//         const req = await fetch(`${API}/upFin_plano_contas`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addAccountsPlan: async (postData) => {
//         const req = await fetch(`${API}/addFin_plano_contas`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delAccountsPlan: async (id) => {
//         const req = await fetch(`${API}/delFin_plano_contas?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },




// /// CENTRO-CUSTO ///////////////////////////////////////////////////////////////

//     getCostCenters: async () => {
//         const req = await fetch(`${API}/fin_centros_custo`)
//         const res = await req.json();
//         return res;
//     },

//     upCostCenters: async (postData) => {
//         const req = await fetch(`${API}/upFin_centros_custo`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addCostCenters: async (postData) => {
//         const req = await fetch(`${API}/addFin_centros_custo`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delCostCenters: async (id) => {
//         const req = await fetch(`${API}/delFin_centros_custo?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },




// /// ENTIDADES //////////////////////////////////////////////////////////////////

//     getEntities: async () => {
//         const req = await fetch(`${API}/fin_entidades`)
//         const res = await req.json();
//         return res;
//     },




// /// FUNCIONARIOS ///////////////////////////////////////////////////////////////

//     getEmployees: async () => {
//         const req = await fetch(`${API}/fin_funcionarios`)
//         const res = await req.json();
//         return res;
//     },

//     upEmployees: async (postData) => {
//         const req = await fetch(`${API}/upFin_funcionarios`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addEmployees: async (postData) => {
//         const req = await fetch(`${API}/addFin_funcionarios`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delEmployees: async (id) => {
//         const req = await fetch(`${API}/delFin_funcionarios?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },




// /// CLIENTES ///////////////////////////////////////////////////////////////////

//     getCustomers: async () => {
//         const req = await fetch(`${API}/fin_clientes`)
//         const res = await req.json();
//         return res;
//     },

//     upCustomers: async (postData) => {
//         const req = await fetch(`${API}/upFin_clientes`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addCustomers: async (postData) => {
//         const req = await fetch(`${API}/addFin_clientes`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delCustomers: async (id) => {
//         const req = await fetch(`${API}/delFin_clientes?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },




// /// FORNECEDORES ///////////////////////////////////////////////////////////////

//     getSuppliers: async () => {
//         const req = await fetch(`${API}/fin_fornecedores`)
//         const res = await req.json();
//         return res;
//     },

//     upSuppliers: async (postData) => {
//         const req = await fetch(`${API}/upFin_fornecedores`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addSuppliers: async (postData) => {
//         const req = await fetch(`${API}/addFin_fornecedores`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delSuppliers: async (id) => {
//         const req = await fetch(`${API}/delFin_fornecedores?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },




// /// TRANSPORTADORES ////////////////////////////////////////////////////////////

//     getTransportCompanies: async () => {
//         const req = await fetch(`${API}/fin_transportadores`)
//         const res = await req.json();
//         return res;
//     },

//     upTransportCompanies: async (postData) => {
//         const req = await fetch(`${API}/upFin_transportadores`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addTransportCompanies: async (postData) => {
//         const req = await fetch(`${API}/addFin_transportadores`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delTransportCompanies: async (id) => {
//         const req = await fetch(`${API}/delFin_transportadores?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },




// /// FASE-ATIVIDADES ////////////////////////////////////////////////////////////

//     getPhaseActivities: async (idFase) => {
//         const req = await fetch(`${API}/faseAtividades`)
//         const res = await req.json();
//         return res;
//     },




//     getFasesAtividades: async (idFase) => {
//         const req = await fetch(`${API}/fases_atividades`)
//         const res = await req.json();
//         return res;
//     },

//     upFasesAtividades: async (postData) => {
            
//         const req = await fetch(`${API}/upFases_atividades`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     addFasesAtividades: async (postData) => {
        
//         const req = await fetch(`${API}/addFases_atividades`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     delFasesAtividades: async (id) => {
//         const req = await fetch(`${API}/delFases_atividades?id=${id}`, {
//             method: 'DELETE'
//         });
//         const res = await req.json();
//         return res;
//     },

// /// TIPOS CONTRIBUINTE /////////////////////////////////////////////////////////

//     getTaxpayerTypes: async (idFase) => {
//         const req = await fetch(`${API}/fin_tipos_contribuinte`)
//         const res = await req.json();
//         return res;
//     },










// ////////////////////////////////////////////////////////////////////////////////

//     getAtividades: async (idFase) => {
//         const req = await fetch(`${API}/atividades?idFase=${idFase}`)
//         const result = await req.json();
//         return result;
//     },

//     upAtividades: async (postData) => {
        
//         const req = await fetch(`${API}/upAtividades`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const session = await req.json();
//         return session;
//     },

//     addComments: async (postData) => {
        
//         const req = await fetch(`${API}/addComentarios`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const session = await req.json();
//         return session;
//     },


//     addProcess: async (postData) => {
        
//         const req = await fetch(`${API}/addProcess`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
//         return res;
//     },

//     upProcess: async (postData) => {
        
//         const req = await fetch(`${API}/upProcess`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();      
//         return res;
//     },

//     addPhase: async (postData) => {
//         const req = await fetch(`${API}/addPhase`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
        
//         return res;
//     },

//     upPhase: async (postData) => {
        
//         const req = await fetch(`${API}/upPhase`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();      
//         return res;
//     },

//     addAtvs: async (postData) => {
        
//         const req = await fetch(`${API}/addAtvs`, {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(
//                 postData
//             )
//         });
//         const res = await req.json();
        
//         return res;
//     },

//     getAtvs: async (idTipoFase) => {
//         const req = await fetch(`${API}/tipoFaseTipoAtvs?idTipoFase=${idTipoFase}`)
//         const res = await req.json();
//         return res;
//     },



};

