
const FIN_API = process.env.REACT_APP_BACKEND;
// const token = localStorage.getItem('SessionID')
// console.log("TOKEN", localStorage.getItem('SessionID'))




export default {


/// - FIN - RECEITAWS - ////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getReceitaWS: async (cnpj) => {
  const req = await fetch(`https://receitaws.com.br/v1/cnpj/${cnpj}`);
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - ARQUIVOS - ///////////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

uploadFile: async (file) => {
  const token = await localStorage.getItem('SessionID');
  let formData = new FormData()
  formData.append('file', file)
  const req = await fetch(`${FIN_API}/upload2?token=${token}`, {
      method: 'POST',
      headers: {
          Accept: 'multipart/form-data',
      },
      body: formData
  })
  return req;
},


deleteFile: async (file) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/delete2?file=${file}&token=${token}`, {
      method: 'DELETE'
  });
  const res = await req.json();        
  return res;
},

getAccountBalance: async (account) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/balance?account=${account}&token=${token}`)
  const res = await req.json();
  return res;
},

getProposal: async (account) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/proposal?token=${token}`)
  const res = await req.json();
  return res;
},

getProposalState: async (account) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/proposalState&token=${token}`)
  const res = await req.json();
  return res;
},



/// ----------------------------------------------------------------------------


/// - FIN - ACCOUNT PLAN - /////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getAccountPlan: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/accountPlan?token=${token}`)
  const res = await req.json();
  return res;
},


postAccountPlan: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/accountPlan?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delAccountPlan: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/accountPlan?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - BANKS - ////////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getBank: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/bank?token=${token}`)
  const res = await req.json();
  return res;
},


postBank: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/bank?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delBank: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/bank?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();        
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - BANK ACCOUNTS - ////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getBankAccount: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/bankAccount?token=${token}`)
  const res = await req.json();
  return res;
},


postBankAccount: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/bankAccount?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delBankAccount: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/bankAccount?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();        
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - BANK ACCOUNT TYPES - ///////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getBankAccountType: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/bankAccountType?token=${token}`)
  const res = await req.json();
  return res;
},


postBankAccountType: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/bankAccountType?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delBankAccountType: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/bankAccountType?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();        
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - CLIENTS - //////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getClient: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/client?token=${token}`)
  const res = await req.json();
  return res;
},


postClient: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/client?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delClient: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/client?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - COST CENTERS - /////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getCostCenter: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/costCenter?token=${token}`)
  const res = await req.json();
  return res;
},


postCostCenter: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/costCenter?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delCostCenter: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/costCenter?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - CUSTOMERS - //////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getCustomer: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/customer?token=${token}`)
  const res = await req.json();
  return res;
},


postCustomer: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/customer?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delCustomer: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/customer?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - EMPLOYEES - ////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getEmployee: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/employee?token=${token}`)
  const res = await req.json();
  return res;
},


postEmployee: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/employee?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delEmployee: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/employee?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - ENTITIES - /////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getEntity: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/entity?token=${token}`)
  const res = await req.json();
  return res;
},


postEntity: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/entity?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delEntity: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/entity?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},


/// ----------------------------------------------------------------------------


/// - FIN - ENTITY TYPES - /////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getEntityType : async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/entityType?token=${token}`)
  const res = await req.json();
  return res;
},


postEntityType: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/entityType?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delEntityType: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/entityType?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - INTEGRATION - //////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getIntegration: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/integration?token=${token}`)
  const res = await req.json();
  return res;
},


postIntegration: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/integration?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delIntegration: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/integration?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();        
  return res;
},


tokenInter: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/inter?nome=inter&token=${token}`)
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - MY COMPANY - /////////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getMyCompany: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/myCompany?token=${token}`);
  const res = await req.json();
  return res;
},


postMyCompany: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/myCompany?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


/// ----------------------------------------------------------------------------


/// - FIN - OTHERS - ////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getOther: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/other?token=${token}`)
  const res = await req.json();
  return res;
},


postOther: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/other?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delOther: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/other?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - PAYMENTS - /////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getPayment: async (startDate, endDate, description , id, state_id, entityName, value1, value2, account) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/payment?token=${token}&dueDateInitial=${startDate}&dueDateFinal=${endDate}${description}${id}${state_id}${entityName}${value1}${value2}${account}`);
  const res = await req.json();
  return res
},


postPayment: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/payment?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delPayment: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/payment?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - PAYMENT CATEGORIES - ///////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getPaymentCategory: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/paymentCategory?token=${token}`)
  const res = await req.json();
  return res;
},


postPaymentCategory: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/paymentCategory?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delPaymentCategory: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/paymentCategory?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - PAYMENT METHODS - //////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getPaymentMethod: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/paymentMethod?token=${token}`)
  const res = await req.json();
  return res;
},


postPaymentMethod: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/paymentMethod?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delPaymentMethod: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/paymentMethod?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - POSTING STATES - ///////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getPostingState: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/postingState?token=${token}`)
  const res = await req.json();
  return res;
},


postPostingState: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/postingState?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delPostingState: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/postingState?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - RECEIPTS - /////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getReceipt: async (startDate, endDate, description , id, state_id, entityName, value1, value2, account) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/receipt?token=${token}&dueDateInitial=${startDate}&dueDateFinal=${endDate}${description}${id}${state_id}${entityName}${value1}${value2}${account}`);
  const res = await req.json();
  return res
},

// &entityName=${entityName}&state_id=${state_id}&id=${id}

postReceipt: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/receipt?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delReceipt: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/receipt?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - RECEIPT CATEGORIES - ///////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getReceiptCategory: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/receiptCategory?token=${token}`)
  const res = await req.json();
  return res;
},


postReceiptCategory: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/receiptCategory?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delReceiptCategory: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/receiptCategory?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - SUPPLIERS - ////////////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getSupplier: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/supplier?token=${token}`)
  const res = await req.json();
  return res;
},


postSupplier: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/supplier?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delSupplier: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/supplier?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},

/// ----------------------------------------------------------------------------


/// - FIN - TAXPAYER TYPES - ///////////////////////////////////////////////////
/// ----------------------------------------------------------------------------

getTaxpayerType: async () => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/taxpayerType?token=${token}`)
  const res = await req.json();
  return res;
},


postTaxpayerType: async (postData) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/taxpayerType?token=${token}`, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(
    postData
  )
  });
  const res = await req.json();
  return res;
},


delTaxpayerType: async (id) => {
  const token = await localStorage.getItem('SessionID');
  const req = await fetch(`${FIN_API}/taxpayerType?id=${id}&token=${token}`, {
    method: 'DELETE'
  });
  const res = await req.json();
  return res;
},


/// ----------------------------------------------------------------------------







};
