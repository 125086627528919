import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as G from '../../styles/global'
import  Calendar  from '../../components/Calendar';

export const Page01 = () => {
  
    return (
        <G.Container>
          <Calendar />
        </G.Container>
    );
}
