import styled from 'styled-components';

export const MenuContainer = styled.div`
  width: ${props => props.expanded ? '250px' : '35px'};
  height: ${props => props.expanded ? '84vh' : '0px'};
  background-color: ${props => props.expanded ? 'rgba(240, 240, 240, 0.96)' : 'transparent'};
  border: ${props => props.expanded ? '1px solid #bbb' : '1px solid transparent'};
  overflow: hidden;
  left: 25px;
  top: ${props => props.expanded ? '90px' : '90px'};
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 5px;
  z-index: 5px;
  position: absolute;
  flex-direction: column;
  transition: all 0.2s ease;
`;

export const MenuItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 65%;
  margin-top: 10px;
  // scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1px;  
    background-color: #ccc;
  }
`;

export const MenuItem = styled.div`
  color: ${props => props.active ? '#fff' : '#333'};
  background-color: ${props => props.active ? '#f9f9f9' : 'transparent'};
  padding: 12px;
  width: 85%;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  color: #000;
  border-radius: 0px 5px 5px 0px;
  gap: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
  border-bottom: ${props => props.expanded ? '1px dotted #aaa' : '1px dotted transparent'};
  border-left:  ${props => props.active ? '3px solid #ffb400' : '3px solid transparent'};
  &:hover {
    border-left: 3px solid green;
    background-color: #fff;
  }
`;

export const SubMenuItem = styled(MenuItem)`
  font-size: 14px;
  transition: all 0.2s ease;
  justify-content: start;
  width: 80%;
  margin-left: 7px;
  background-color: ${props => props.active ? '#f9f9f9' : '#e9e9e9'};
`;

export const OptionsItem = styled.div`
  color: ${props => props.active ? '#fff' : '#333'};
  padding: 12px;
  width: 95%;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  color: #000;
  border-radius: 0px 5px 5px 0px;
  gap: 10px;
  transition: all 0.5s ease;
  &:hover {
    color: #777;
	  cursor: pointer;
  }
`;

export const SubOptionsItem = styled(OptionsItem)`
  font-size: 12px;
  transition: all 0.2s ease;
  justify-content: start;
  margin-top: 7px;
  width: 90%;
  margin-left: 7px;
`;
