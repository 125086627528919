import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TaskIcon from '@mui/icons-material/Task';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



export const Services = () => {

    const navigate = useNavigate();
    const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

    const { state: user } = useContext(UserContext)

    const [state, setState] = useState('')
    const [list, setList] = useState([]);
    const [modal, setModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [alertError, setAlertError] = useState('')
    const [selectedKey, setSelectedKey] = useState('')
    const [loading, setLoading] = useState(true)
    const [loadingModal, setLoadingModal] = useState(false)

    const [serviceTypes, setServiceTypes] = useState([])
    const [selectedServiceType, setSelectedServiceType] = useState('')
    const [isValid, setIsValid] = useState('')
    const [managerField, setManagerField] = useState(false)

    const [file, setFile] = useState([])
    const [fileName, setFileName] = useState([])
    const [markDeleteFile, setMarkDeleteFile] = useState(false)


    /// DATATABLE STATES /////////////////////////////////////////////////////////

    const [filteredList, setFilteredList] = useState([])
    const [order, setOrder] = useState('')
    const [activeOrder, setActiveOrder] = useState('')
    

    
    /// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

    const handleSearch = (event) => {
      let value = event.target.value.toLowerCase()
      let newArray = list.filter((data) => {
          return data.code.toLowerCase().search(value) !== -1 
              || data.name.toLowerCase().search(value) !== -1 
              || data.value.toLowerCase().search(value) !== -1 
              || data.service_type_name.toLowerCase().search(value) !== -1 
          })
      setFilteredList(newArray);
    }

    const OrderClick = (field) => {
      setActiveOrder(field)
      let result = filteredList.sort(function(a, b){
        if (order === 'asc'){
          return a[field] < b[field] ? -1 : true
        } else {
          return a[field] > b[field] ? -1 : true
        }
      })
      order === 'asc' ? setOrder('desc') : setOrder('asc')
      setFilteredList(result)
    }
    
    

    /// FUNCTIONS ////////////////////////////////////////////////////////////////


    const loadBases =  async () => {

      let res1 = await Api.getServiceType()
      setServiceTypes(res1)

    }
    
    const GetService = async () => {
      setLoading(true)
      let res = await Api.getServices('0');
      setList(res)
      setFilteredList(res)
      setLoading(false)
    }

    
    const onSubmit = async (data) => {
      // console.log("onSubmit > data: ", data)

      if(file.length !== 0) {
        let res = await Api.uploadFile(file)
        data["icon"] = file.name
      }
      if(markDeleteFile === true) {
        Delete(filteredList[selectedKey].icon)
        data["icon"] = null
      }

      switch (modalType) {
        case 1:
          const userAdd = ({
			      created_by: user.id,
            changed_by: user.id
          })
          const add = ({...data, ...userAdd})
          let res1 = await Api.postService(add)
          // console.log("addBank: ", res1)
        break;

        case 2:
          const userUpd = ({
            changed_by: user.id
          })
          const upd = ({...data, ...userUpd})
          let res2 = await Api.postService(upd)
          // console.log("upgBank: ", res2)
        break;

        default:
        break;
      }
      CloseModalClick()
    };


    const DelService = async (id) => {
      let res = await Api.delService(id);
      GetService()
    }


    const ActService = async (id, estado) => {
      const data = ({
        id: id, 
        state: estado, 
        changed_by: user.id
      })
      let res = await Api.postService(data)
      GetService()
    }


    const AddClick = () => {
      setModalType(1)
      setModal(true)
      loadBases()
    }  
  
    const EditClick = (key) => {
      setSelectedKey([key])
      setSelectedServiceType(filteredList[key].service_type_id)
      setModalType(2)
      setModal(true)
      loadBases()
    }
  
    const DelClick = (key) => {
      setSelectedKey([key])
      setModalType(3)
      setModal(true)
    }
  
    const ActClick = (key) => {
      setSelectedKey([key])
      setModalType(4)
      setModal(true)
    }
  
  
    const CloseModalClick = () => {
      setLoading(true)
      setSelectedKey('')
      setSelectedServiceType('')
      setAlertError('')
      GetService()
      setModal(false)
      setModalType('')
      setFileName([])
      setMarkDeleteFile(false)
      reset()
      setLoading(false)
    }
  
      const CloseModalClick2 = () => {
      setLoading(true)
      setSelectedKey('')
      setSelectedServiceType('')
      setAlertError('')
      setModal(false)
      setModalType('')
      setFileName([])
      setMarkDeleteFile(false)
      reset()
      setLoading(false)
    }


    const Download = (file) => {
      window.open(`${process.env.REACT_APP_BACKEND}/download2?file=${file}&token=${localStorage.getItem('SessionID')}`)
    }
  
  
    const Delete = async (file) => {
      if(file.length !== 0) {
        let res = await Api.deleteFile(file)
      } else { }
    }





/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

document.onkeydown = function (e) {
  if (e.key === 'Escape') {
    CloseModalClick();
  }
}



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{
    GetService();
  }, [state]);


/// CONSOLE LOG ////////////////////////////////////////////////////////////////
  // console.log("serviceTypes", serviceTypes)
  console.log(list)





/// RETURN /////////////////////////////////////////////////////////////////////

    return (
      <G.Container>

        <G.Header>

          <G.HeaderTitleArea style={{ width: '240px' }}>
            <MiscellaneousServicesOutlinedIcon style={{ fontSize: '32px', margin: '0px 0px 0px 0px' }} />
            <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 8px', border: '0.04rem solid #333', borderRadius: '4px' }} />
            <G.MainTitle style={{ fontSize: 'var(--font-size-title)' }}>Serviços</G.MainTitle>
          </G.HeaderTitleArea>
          <G.HeaderTitleArea style={{ width: '320px' }}>
            
          </G.HeaderTitleArea>
          <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
            <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
              <G.SearchIconArea>
                <SearchIcon />
              </G.SearchIconArea>
              <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
            </G.SearchArea>        
          </G.HeaderTitleArea>
          <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
            <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
              <AddIcon fontSize='small'/>
              {'Adicionar'}
            </G.Button>
          </G.HeaderTitleArea>

        </G.Header>

          <G.Body>

            {loading &&  
              <G.Logo src={"/loading.svg"} />
            }

            {(!loading && filteredList.length === 0) &&
              <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
                <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
                <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
                  Não existem itens para a seleção atual! 
                </G.MainTitle>
              </G.OptionsArea>
            }

            {(!loading && filteredList.length > 0)  && 
              <>
                <G.Box>
                  <G.TableHeader>
                    <G.OptionsArea style={{ display: 'flex', flex: '1', justifyContent: 'space-around', padding: '0px' }}>
                      {/* <G.FieldArea size={'48px'} style={{ padding: '0px', justifyContent: 'center' }}><G.DistacInfoText></G.DistacInfoText></G.FieldArea> */}
                      <G.FieldArea size={'080px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('id')}><G.DistacInfoText>Código</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'code') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'500px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('name')}><G.DistacInfoText>Nome</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'name') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'100px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('value')}><G.DistacInfoText>Valor</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'value') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'160px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('service_type_name')}><G.DistacInfoText>Tipo de Serviço</G.DistacInfoText>
                        {(activeOrder === '' || activeOrder === 'service_type_name') &&
                          <>
                            {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                          </>
                        }
                      </G.FieldArea>
                      <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                    </G.OptionsArea>
                  </G.TableHeader>

                  {(loading === true && filteredList.length === 0 ) &&  
                    <G.Loading2 src={"/loading.svg"} />
                  }

                  <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                    {(loading === false && filteredList.length === 0) && 
                      <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                        *** Não existem itens a exibir. ***
                      </G.Text>
                    }
                    
                    {(loading === false && filteredList.length !== 0) &&
                      <>
                        {filteredList.map((item, key) => (
                          <G.TableArea key={key} style={{ justifyContent: 'space-around'}}>
                            {/* <G.FieldArea size={'36px'} style={{ padding: '0px', justifyContent: 'center' }}><G.Text><C.ContactTypeIconArea style={{ border:  item.state === 1 ? '2px solid #2E8B57' : '2px solid #E95839' }}> */}
                            {/* <G.FieldArea size={'48px'} style={{ padding: '0px', justifyContent: 'center' }}><G.Text><C.ContactTypeIconArea style={{ border: 'var(--circular-border)' }}>
                              <AccountBalanceIcon style={{ fontSize: '22px', color: 'var(--icon-color)' }}/></C.ContactTypeIconArea></G.Text>
                            </G.FieldArea> */}
                            <G.FieldArea size={'080px'} style={{ justifyContent: 'start' }}><G.Text>{item.id}</G.Text></G.FieldArea>
                            <G.FieldArea size={'500px'} style={{ justifyContent: 'start' }}><G.Text>{item.name}</G.Text></G.FieldArea>
                            <G.FieldArea size={'500px'} style={{ justifyContent: 'start' }}><G.Text>{item.details}</G.Text></G.FieldArea>
                            <G.FieldArea size={'100px'} style={{ justifyContent: 'start' }}><G.Text>{parseFloat(item.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</G.Text></G.FieldArea>
                            <G.FieldArea size={'160px'} style={{ justifyContent: 'start' }}><G.Text>{item.service_type_name}</G.Text></G.FieldArea>
                            <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}>
                              <G.IconButton onClick={() => EditClick(key) + setFileName(filteredList[key].icon)} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}><EditIcon fontSize="small" /></G.IconButton>
                              {/* <G.IconButton onClick={() => ActService(item.id, item.state === 0 ? 1 : 0)} style={{ backgroundColor: item.state === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>{item.state === 0 ? <CheckIcon fontSize="small"/> : <BlockIcon fontSize="small"/>}</G.IconButton> */}
                              {/* <G.IconButton onClick={() => ActClick(key)} style={{ backgroundColor: item.state === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>{item.state === 0 ? <CheckIcon fontSize="small"/> : item.state === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}</G.IconButton> */}
                              <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}><DeleteIcon fontSize="small"/> </G.IconButton>
                            </G.FieldArea>
                          </G.TableArea>
                        ))}
                      </>
                    }
                  </G.TableBody>
                </G.Box>
              </>
            }

          </G.Body>

          <G.Footer style={{ color: '#333', fontSize: '14px' }}>
            {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
          </G.Footer>

          {modal !== false &&
            <M.ModalContainer>
              <M.ModalContentSmall style={{ width: '550px' }}>

                <M.ModalHeader style={{ backgroundColor: modalType === 1 ? '#BDF5D6' : modalType === 2 ? '#C8E4FF' : modalType === 3 ? '#FCAC9B' : (filteredList[selectedKey].state === 0 ? '#BDF5D6' : '#ccc'), justifyContent: 'space-between', bordeRadius: '15px' }}>
                  <C.ModalTitle>{modalType === 1 ? 'Adicionar Serviço' : modalType === 2 ? 'Editar Serviço' : modalType === 3 ? 'Excluir Serviço' : (filteredList[selectedKey].state === 0 ? 'Ativar Serviço' : 'Desativar Serviço')}</C.ModalTitle>
                    <G.InfoArea>
                      <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer', color: '#333' }} onClick={CloseModalClick} />
                    </G.InfoArea>
                </M.ModalHeader>

                <M.ModalBody>

                  {(modalType === 2 || modalType === 3) && 
                    <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].id} {...register("id")} />
                  }

                  {(modalType === 1 || modalType === 2) &&
                    <G.BoxBodyScroll style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>

                      <G.FormInputArea style={{ width: '180px' }}>
                        <G.FormInputLabel>
                          Código
                        </G.FormInputLabel>
                        <G.FormInput autoFocus defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].code} {...register("code", { required: true })} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '308px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Tipo
                        </G.FormInputLabel>
                        <G.FormSelect 
                          {...register("service_type_id", { setValueAs: v => v === '' ? null : v })}
                          onChange={(e) => setSelectedServiceType(e.target.value)}
                        >
                          <option 
                            disabled selected value={modalType === 1 ? '' : filteredList[selectedKey].service_type_id}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].service_type_name}
                          </option>
                          {serviceTypes.map(i => (
                            <option key={i.id} value={i.id}>{i.name}</option>
                          ))}
                        </G.FormSelect>
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '500px' }}>
                        <G.FormInputLabel>
                          Nome
                        </G.FormInputLabel>
                        <G.FormInput autoFocus defaultValue={modalType === 1 ? '' : filteredList[selectedKey].name} {...register("name", { required: true })} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '200px' }}>
                        <G.FormInputLabel>
                          Valor
                        </G.FormInputLabel>
                        <G.FormInput 
                          defaultValue={modalType === 1 ? '' : parseFloat(filteredList[selectedKey].value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} 
                          {...register("value", {setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))}, { required: true })} 
                        />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '240px', border: 'none' }}></G.FormInputArea>

                      {(selectedServiceType === 1 || selectedServiceType === 2 || selectedServiceType === 3) && 
                        <G.FormInputArea style={{ width: '160px' }}>
                          <G.FormInputLabel>
                            Cota GB
                          </G.FormInputLabel>
                          <G.FormInput autoFocus defaultValue={modalType === 1 ? '' : filteredList[selectedKey].quota_gb} {...register("quota_gb", { setValueAs: v => Number(v) }, { required: (selectedServiceType === 1 || selectedServiceType === 2 || selectedServiceType === 3) ? true : false })} />
                        </G.FormInputArea>
                      }
                      {(selectedServiceType === 2) && 
                        <G.FormInputArea style={{ width: '160px' }}>
                          <G.FormInputLabel>
                            Cota Contas
                          </G.FormInputLabel>
                          <G.FormInput autoFocus defaultValue={modalType === 1 ? '' : filteredList[selectedKey].quota_accounts } {...register("quota_accounts", { setValueAs: v => Number(v) }, { required: selectedServiceType === 2 ? true : false })} />
                        </G.FormInputArea>
                      }

                      {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '16px 0px 16px -16px' }}>
                        {(Object.keys(errors).length > 0) &&  
                          <div role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '92%', padding: '7px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                            <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                            <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{'TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS!'}</div>
                            <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                          </div>
                        }
                      </div>

                      {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                      <div style={{ display: 'flex', width: '520px', height: '28px', padding: '1px', margin: '-16px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                        Imagem (*)
                      </div>

                      {modalType === 1 && 
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <G.FormInputArea style={{ width: '380px', marginBottom: '0px' }}>
                            <G.Input 
                              type="file" 
                              accept="image/png, image/jpeg, image/webp, .svg" 
                              style={{ width: '100%', borderRadius: '0px 5px 5px 0px', backgroundColor: '#F5F5F5' }} 
                              onChange={e => setFile(e.target.files[0])} 
                            />
                          </G.FormInputArea>
                          <p style={{ margin: '2px 0px 0px 8px', color: '#777' }}>(*) Apenas um (1) arquivo no formato PDF.</p>
                        </div>
                      }
                      {modalType !== 1 && 
                        <>
                          {(filteredList[selectedKey].icon === null || filteredList[selectedKey].icon === '') &&
                            <G.FormInputArea style={{ width: '380px' }}>
                              <G.Input 
                                type="file" 
                                accept="image/png, image/jpeg, image/webp, .svg" 
                                style={{ width: '100%', borderRadius: '5px', backgroundColor: '#F5F5F5' }} 
                                onChange={e => setFile(e.target.files[0])} 
                              />
                            </G.FormInputArea>
                          }
                          {(filteredList[selectedKey].icon !== null && filteredList[selectedKey].icon !== '') && 
                            <>
                              <G.BoxBody>
                                <G.FakeButton style={{ minWidth: '030px', width:'70px', padding: '6px 6px 6px 4px', backgroundColor: markDeleteFile === true ? '#aaa' : '#E95839' }}>
                                  IMG
                                </G.FakeButton>
                                <G.FakeButton style={{ width: '310px', marginLeft: '-20px', backgroundColor: markDeleteFile === true ? '#888' : '#34495e' }}>
                                  {filteredList[selectedKey].icon}
                                </G.FakeButton>
                                <G.DownloadButton 
                                  style={{ minWidth: '030px', marginLeft: '-20px', backgroundColor: markDeleteFile === true ? '#aaa' : '#2E8B57' }} 
                                  onClick={() => Download(filteredList[selectedKey].icon)}
                                >
                                  <FileDownloadIcon />
                                </G.DownloadButton>
                              </G.BoxBody>
                              <G.BoxBody>
                                <G.IconButton style={{ backgroundColor: markDeleteFile === false ? '#E95839' : '#1E90FF', margin: '0px 1px' }} onClick={() => setMarkDeleteFile(markDeleteFile === false ? true : false)}>
                                  {markDeleteFile === false ? <DeleteIcon fontSize="small"/> : <ClearIcon fontSize="small"/>}
                                </G.IconButton>
                              </G.BoxBody>
                            </>
                          }
                        </>
                      }

                      <div style={{ display: 'flex', width: '520px', height: '28px', padding: '1px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                      
                      </div>

                      {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                      <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '20px', marginBottom: '20px' }}>
                        <G.FormInputLabel style={{ width: '500px', justifyContent: 'flex-start', border: 'none', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                          Itens
                        </G.FormInputLabel>
                        <G.TextArea {...register("items", { setValueAs: v => v === '' ? null : v })} 
                              style={{ width: '480px', height: '60px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                        >
                          {modalType === 1 ? '' : filteredList[selectedKey].items}
                        </G.TextArea>
                      </G.FieldArea>

                      {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                      <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '0px', marginBottom: '20px' }}>
                        <G.FormInputLabel style={{ width: '500px', justifyContent: 'flex-start', border: 'none', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                          Detalhes
                        </G.FormInputLabel>
                        <G.TextArea {...register("details", { setValueAs: v => v === '' ? null : v })} 
                              style={{ width: '480px', height: '60px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                        >
                          {modalType === 1 ? '' : filteredList[selectedKey].details}
                        </G.TextArea>
                      </G.FieldArea>

                      {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                      <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '0px', marginBottom: '20px' }}>
                        <G.FormInputLabel style={{ width: '500px', justifyContent: 'flex-start', border: 'none', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                          Observação
                        </G.FormInputLabel>
                        <G.TextArea {...register("note", { setValueAs: v => v === '' ? null : v })} 
                              style={{ width: '480px', height: '60px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                        >
                          {modalType === 1 ? '' : filteredList[selectedKey].note}
                        </G.TextArea>
                      </G.FieldArea>

                    </G.BoxBodyScroll>
                  }
                </M.ModalBody>

                {(modalType === 3) && 
                  <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <G.Text style={{ margin: '10px 0px' }}>
                      Deseja excluir o Serviço:
                    </G.Text>
                    <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                      <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                        {filteredList[selectedKey].name}
                      </G.FormInputLabel>
                    </G.FormInputArea>
                  </M.ModalBody>
                }

                {(modalType === 4) && 
                  <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <G.Text style={{ margin: '10px 0px' }}>
                      Deseja {filteredList[selectedKey].state === 0 ? 'ativar' : 'desativar'} o Serviço:
                    </G.Text>
                    <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                      <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                        {filteredList[selectedKey].name}
                      </G.FormInputLabel>
                    </G.FormInputArea>
                  </M.ModalBody>
                }

                <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  {alertError !== '' &&
                    <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
                  }
                  
                  {(modalType === 1 || modalType === 2) &&
                    // <G.Button onClick={SendBanks} style={{backgroundColor: '#333', width: '120px'}}>Enviar</G.Button>
                    // <G.Button style={{backgroundColor: '#333'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                    <>
                      <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                      <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                    </>
                  }

                  {modalType === 3 && 
                    <>
                      <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                      <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelService(filteredList[selectedKey].id) + CloseModalClick()}>Sim</G.Button>
                    </>
                  }
                  {modalType === 4 && 
                    <>
                      <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                      <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActService(filteredList[selectedKey].id, filteredList[selectedKey].state === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                    </>
                  }
                </M.ModalFooter>

              </M.ModalContentSmall>
            </M.ModalContainer>
          }
      </G.Container>

    );
}
