import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate,useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import 'moment/locale/pt-br'
import {  Margin, usePDF } from 'react-to-pdf';
import { useWindowSize } from "@uidotdev/usehooks";
import ReactQuill from 'react-quill';

import './ticket.css';

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'


import { Search } from '../../components/Search/search'
import { PageHeader } from '../../components/PageHeader/PageHeader'
import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import CopyrightIcon from '@mui/icons-material/Copyright';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import TaskIcon from '@mui/icons-material/Task';
import ArticleIcon from '@mui/icons-material/Article';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';



import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import zIndex from '@mui/material/styles/zIndex';


export const Tickets = () => {
  const navigate = useNavigate();
  const {register, handleSubmit, reset, unregister} = useForm({mode: 'onSubmit'});
  const size = useWindowSize();


  const { state: user } = useContext(UserContext)
  const [searchParams] = useSearchParams()

  const ref = useRef([]);

  moment.locale('pt-br')

  const { toPDF, targetRef } = usePDF({
    method: "save",
    filename: "usepdf-example.pdf",
    page: { margin: Margin.NONE },
  });



  const year = new Date().getFullYear()
  const month = new Date().getMonth() +1

  const [list, setList] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);


  const [fasesList, setFasesList] = useState([])
  const [switchButton, setSwitchButton] = useState(false)
  const [state, setState] = useState('')
  const [empty, setEmpty] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [painel, setPainel] = useState(true)
  const [payModal, setPayModal] = useState(false)
  const [selectedProcess, setSelectedProcess] = useState('')
  const [showAddComments, setShowAddComments] = useState(false)
  const [addComments, setAddComments] = useState('')
  const [acts, setActs] = useState('')
  const [actsDetails, setActsDetails] = useState('')
  const [prevValue, setPrevValue] = useState('')
  const [valuePay, setValuePay] = useState('')
  const [prevPay, setPrevPay] = useState(false)
  const [payModalPdf, setPayModalPdf] = useState(false)
  const [clientPay, setClientPay] = useState('')
  const [selectedPayEntity, setSelectedPayEntinty] = useState('')
  const [selectedPayDate, setSelectedPayDate] = useState('')
  const [selectedPayType, setSelectedPayType] = useState('')

  // const [startDate, setStartDate] = useState(year+'-'+month+'-01')
  // const [endDate, setEndDate] = useState(year+'-'+month+'-31')
  var firstday = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD');
  var lastday = moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(firstday)
  const [endDate, setEndDate] = useState(lastday)


  const [showReply, setShowReply] = useState(false)
  const [showClose, setShowClose] = useState(false)


  const [comments, setComments] = useState('')
  const [selectedActs, setSelectedActs] = useState([])
  const [comentarios, setComentarios] = useState([]);

  const [company, setCompany] = useState([])
  const [lawyers, setLawyers] = useState([])
  const [payTypes, setPayTypes] = useState([])
  const [selectedPhase, setSelectedPhase] = useState([])
  const [atvs, setAtvs] = useState([])

  const [tokens, setTokens] = useState(false)

  const [convertedFile, setConvertedFile] = useState([])
  const [actsFull, setActsFull] = useState('')
  const [addFase, setAddFase] = useState(false)
  const [users, setUsers] = useState([]);
  const [phaseType, setPhaseType] = useState([])

// DATATABLE STATES /////////////////////////////////////////////////////////

const [filteredList, setFilteredList] = useState([])



  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    if(value){
      let result = [];
      result = filteredList.filter((data) => {
        return data.a_body.toLowerCase().search(value) !== -1
        || data.title.toLowerCase().search(value) !== -1 
        || data.customer.toLowerCase().search(value) !== -1 
        || data.customer_id.toLowerCase().search(value) !== -1 
        || data.tn.toString().search(value) !== -1; 
      });
      setFilteredList(switchButton ? result.filter(qitem => (qitem.user_id === user.id || qitem.responsible_user_id === user.id)) : result)
    }else{
      Filter(state, switchButton)
    }
  }

  const handleSearchArticle = (event) => {
    let value = event.toLowerCase()
    if(value !== 'reset()'){
      let result = [];
      result = articles.filter((data) => {
        return data.a_body.toLowerCase().search(value) !== -1
        || data.a_subject.toLowerCase().search(value) !== -1 
        || data.a_from.toLowerCase().search(value) !== -1 
      });
      setFilteredArticles(result)
    }else{
      setFilteredArticles(articles)
      ref.current.value = ''
    }

  }

  const Download = async (article_id, fileName, fileContent) => {
    let res = await Api.getArticleFile(article_id)
    let url = `data:${fileContent};base64,${res}`;
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }


  const GoClick = (data) => {
    navigate(data);
  }


  const Filter = (stateToggle, switchToggle) => {
    document.getElementById('search').value = '';
    if (switchToggle === true) {
      setState('')
      if (stateToggle){
        setFilteredList(list.filter(qitem => qitem.state_type === stateToggle && (qitem.user_id === user.id || qitem.responsible_user_id === user.id) ))
        setState(stateToggle) 
        }else{
        setFilteredList(list.filter(qitem => (qitem.user_id === user.id || qitem.responsible_user_id === user.id)))
        }

    }else{
      setState('')
      if (stateToggle) {
      setFilteredList(list.filter(qitem => qitem.state_type === stateToggle ))
      }else{
      setFilteredList(list)
      }
    }

  }


  const TicketClick = async (key, codProcess) => {
    setLoadingModal(false)
    setSelectedProcess(key)
      let res = await Api.getCompanies()
      setCompany(res)
      let res1 = await Api.getLawyers()
      setLawyers(res1)
      let res2 = await Api.getUsers()
      setUsers(res2)
      let res3 = await Api.getTiposFase()
      setPhaseType(res3)
      let res4 = await Api.getFases(codProcess);
      setFasesList(res4)
    setModal(true)
  }

  const CloseModalClick = () => {
    setModal(false)
    setFilteredArticles(false)
    setArticles(false)
    ref.current.value = ''
  }

  

  const addNewComments = async (idFase) => {
    // setLoading(true)
    let res = await Api.addComments({
      "fk_fase": idFase,
      "fk_usuario": user.id,
      "comentario": addComments,
      "data": moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setAddComments('')
    setShowAddComments(false)
    setComments('')
    // setLoading(false);
  }

  const getComentarios = async (key, idFase) => {
    // setLoading(true)
    let res = await Api.getComentarios(idFase);
    if (res) {
        setComentarios(res)
        setComments(key)
    } else {
    }
    // setLoading(false);
  }

  const selectedTicket = async (id, ticketID) => {
    let res = await Api.getArticle(ticketID)
    setArticles(res)
    setFilteredArticles(res)
    setTicket(list[id])  
    setModal(true)
    setLoading(true)
    setLoading(false);
  }




  /// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      // console.log("APERTOU ESC!")
      setModal(false)
    }
  }


  /// USE EFECT //////////////////////////////////////////////////////////////////


	const getTickets = async () => {
    setLoading(true)
    let res = await Api.getTickets(); 
        setList(res)
        setFilteredList(res)
        setTicket(res[5])
    setLoading(false)                
}

useEffect(()=>{

    getTickets()
    
}, []);


/// CONSOLE LOG ////////////////////////////////////////////////////////////////
  
console.log(filteredArticles)


/// RETURN /////////////////////////////////////////////////////////////////////

  return (
    <G.Container >
        <G.Header>
        <G.HeaderTitleArea>
          <TaskIcon style={{ fontSize: '32px', margin: '0px 0px 0px 0px' }}/>
          <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 8px', border: '0.04rem solid #333', borderRadius: '4px' }} />
          <h2>Chamados</h2>
          <div style={{marginLeft: '10px', backgroundColor: '#333', padding: '7px', color: '#fff', fontWeight: 'bold', borderRadius: '5px'}}>{filteredList.length}</div>
        </G.HeaderTitleArea>

        <G.SearchArea style={{gap: '15px'}}>
            <G.SwitchArea>
              <TaskIcon onClick={() => Filter(1, switchButton ? true : false) + setState(1)} style={{ fontSize: state === 1 ? '32px' : '26px', outline: 'none', color: '#84b6f4', cursor: 'pointer' }}/>
              <TaskIcon onClick={() => Filter(2, switchButton ? true : false) + setState(2)} style={{ fontSize: state === 2 ? '32px' : '26px', outline: 'none', color: '#51B476', cursor: 'pointer' }}/>
              <TaskIcon onClick={() => Filter(4, switchButton ? true : false) + setState(4)} style={{ fontSize: state === 4 ? '32px' : '26px', outline: 'none', color: '#ffb400', cursor: 'pointer' }}/> 
              <TaskIcon onClick={() => Filter(3, switchButton ? true : false) + setState(3)} style={{ fontSize: state === 3 ? '32px' : '26px', outline: 'none', color: '#F15068', cursor: 'pointer' }}/>
              {state !== '' &&
                <CloseIcon onClick={() => Filter(false, switchButton)  
                 + setState('')} style={{ cursor: 'pointer'}}/>
              }
            </G.SwitchArea> 
            <G.SwitchArea aria-label="">
              <BusinessIcon style={{ paddingBottom: '2px', borderBottom: switchButton ? 'none' : '3px solid #7137C8', color: switchButton ? '#7C7484' : '#7137C8' }}/>
              <G.CircleButton onClick={() => setSwitchButton(switchButton === false ? true : false) + Filter(false, switchButton ? false : true)}>
                <SwapHorizIcon 
                  fontSize="small" 
                  style={{ borderRadius: '50%' }} 
                />
              </G.CircleButton>
              <AccountCircleIcon  
                style={{ paddingBottom: '2px', borderBottom: switchButton ? '3px solid #7137C8' : 'none', color: switchButton ? '#7137C8' : '#7C7484' }}
              />
            </G.SwitchArea>
          <G.SwitchArea>
            <SearchIcon />
            <G.SearchInput id='search' onChange={(event) => handleSearch(event)}></G.SearchInput>
          </G.SwitchArea>
              <G.SwitchArea style={{ width: 'auto', justifyContent: 'flex-end'}}>
                  <G.CircleButton 
                    style={{ marginRight: '5px' }} 
                    onClick={() =>  GoClick('/painel')}>
                    <TaskAltOutlinedIcon style={{ borderRadius: '50%' }}/>
                  </G.CircleButton> 
                {/* BOTÃO – PROCESSOS ARQUIVADOS */}
                <G.Button onClick={GoClick} style={{ width: '100px', backgroundColor: '#333' }}>
                  Arquivados
                  <OpenInNewIcon fontSize="small" />
                </G.Button>
              </G.SwitchArea>
        </G.SearchArea>
      </G.Header>



      <G.Body style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '15px' }}>
       {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
            <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {!loading  &&
          filteredList.map((item, key)=>(
          <G.Card key={key} style={{ width: '492px', minHeight: '336px', margin: '25px' }}>

            <G.CardHeader  >
              <G.CardHeaderLogo>
              <TaskIcon style={{color: item.state_type === 2 ? '#51B476' :
                                              item.state_type === 1 ? '#84b6f4' :
                                              item.state_type === 4 ? '#ffb400' : '#F15068'}}  fontSize="large"/>
              </G.CardHeaderLogo>
              <G.CardHeaderInfo>
                <G.CardHeaderNumber style={{marginBottom: '2px'}}> {item.title}</G.CardHeaderNumber>
                <G.Text> Chamado: {item.tn}</G.Text>
              </G.CardHeaderInfo>
                <G.CardHeaderLogo >
                  <OpenInNewIcon fontSize="large" onClick={() => selectedTicket(key, item.id)} style={{ color: '#333', cursor: 'pointer' }} />
                </G.CardHeaderLogo>
            </G.CardHeader>
            <G.CardBody style={{  }}>
              <G.CardBodyA>
                <G.CardBodyText style={{ justifyContent: 'flex-start', padding: '6px 8px' }}>
                  <BusinessIcon style={{ marginRight: '4px', fontSize: '26px' }}/>
                    {item.customer_id}
                </G.CardBodyText>
                <G.CardBodyText style={{ justifyContent: 'flex-start', fontSize: '13px', backgroundColor: '#F5F5F5' }}>
                   <G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '28px', boxShadow: '0px 1px 3px 1px #bbb', marginRight: '5px'}} src={item.customer_photo } />    
                  <strong>
                    { item.customer}
                  </strong>
                </G.CardBodyText>
                <G.CardBodyText style={{ justifyContent: 'flex-start', padding: '5px 7px', backgroundColor: '#fff', borderLeft: 'solid 4px #33309d'  }}>
                  <G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '28px', boxShadow: '0px 1px 3px 1px #bbb', marginRight: '5px'}} src={item.owner_photo } />    
                  {item.owner}
                </G.CardBodyText>
                <G.CardBodyText style={{ justifyContent: 'flex-start', padding: '5px 7px', backgroundColor: '#fff', borderLeft: 'solid 4px #277384'  }}>
                  <G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '28px', boxShadow: '0px 1px 3px 1px #bbb', marginRight: '5px'}} src={item.resp_photo } />    
                  {item.responsible }
                </G.CardBodyText>
              </G.CardBodyA>
              <C.CardBodyB style={{ alignItems: 'center', justifyContent: 'start', fontSize: '11px' }}>
              <G.CardBodyText style={{ justifyContent: 'flex-start', backgroundColor: '#F5F5F5', borderLeft: '4px solid #2E8B57', width: '145px' }}>
                    {item.state.length > 23 ? item.state.substring(0,23)+' ...' : item.state}
                </G.CardBodyText>  
              <G.CardBodyText style={{ borderLeft: 'solid 5px red' , padding: '6px 8px', width: '145px', justifyContent: 'start' }}>
                <G.InfoText style={{backgroundColor: '#555', color: '#fff', padding: '4px 5px', borderRadius: '5px', fontWeight: 'bold', marginRight: '10px', fontSize: '10px'}}>SLA</G.InfoText>
                {item.sla}
              </G.CardBodyText>  
              <G.CardBodyText style={{ justifyContent: 'start', padding: '6px 8px', width: '145px' }}>
                <TypeSpecimenIcon style={{  fontSize: '24px', marginRight: '10px', color: '#555' }} /> 
                {item.queue}
              </G.CardBodyText>      
              <G.CardBodyText style={{  backgroundColor: '#fff', border: 'solid 1px #ffb400', justifyContent: 'start', padding: '6px 8px' , width: '150px' }}>
                <G.InfoText style={{backgroundColor: '#555', color: '#fff', padding: '4px 5px', borderRadius: '5px', fontWeight: 'bold', marginRight: '10px', marginLeft: '2px', fontSize: '10px'}}>TP</G.InfoText>
                {item.type }
              </G.CardBodyText>
              <G.CardBodyText style={{ backgroundColor: '#fff', border: 'solid 1px #ffb400', justifyContent: 'start', padding: '6px 8px', width: '150px' }}>
                <MiscellaneousServicesIcon style={{ marginRight: '10px', fontSize: '20px', color: '#555' }} /> 
                {item.service}
              </G.CardBodyText>
              </C.CardBodyB>
              </G.CardBody>
              <G.CardFooter style={{ padding: '4px', margin: '0px', backgroundColor: '#fff', alignItems: 'start' }}>
                <G.CardHeaderInfo style={{ padding: '7px', fontSize: '12px'}}>
                  <G.CardHeaderNumber style={{ marginBottom: '5px', fontWeight: 'normal',  color: '#333', borderBottom: '1px solid #777', justifyContent: 'space-between', width: '100%' }}>
                    <G.Text style={{marginBottom: '5px', fontSize: '12px', fontWeight: 'bold', marginRight: '5px'}}>
                      <ArticleIcon style={{color: item.article_sender_type_id === 3 ? '#ffb400' : item.article_sender_type_id === 1 ? '#1E90FF' : '#333', marginRight: '5px'}}/>{item.a_from.replace(/ *\<[^)]*\> */g, '')}
                    </G.Text>
                    <G.Text style={{marginBottom: '5px', color: '#333', fontSize: '11px'}}>{moment(item.a_create_time).format("YYYY-MM-DD HH:mm")}</G.Text>
                    </G.CardHeaderNumber>
                  <C.CardHeaderNumber style={{ fontSize: '12px', fontWeight: 'normal', color: '#333', marginLeft: '3px'  }}>
                      { item.a_body.length > 140 ? item.a_body.replace(/<[^>]+>/g, '').substring(0,140)+' ...' : item.a_body.replace(/<[^>]+>/g, '') }</C.CardHeaderNumber>
                </G.CardHeaderInfo>
              </G.CardFooter>
            </G.Card>
          ))
        }
      </G.Body>

      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p>
      </G.Footer>
      {(modal !== false) &&
       <M.ModalContainer >
          <M.ModalContent style={{width: '1200px', height: '600px', borderRadius: '10px'}}>
          <PageHeader title={ticket.tn } width={'1140px'} fontSize={'16px'} minHeight={'60px'} subTitle={ticket.title}
                      icon={<TaskIcon style={{color: ticket.state_type === 1 ? '#84b6f4' : ticket.state_type === 2 ? '#51B476' :ticket.state_type === 4 ? '#fda14b' : '#F15068', fontSize: '30px'}}/>}
          > 
             <G.Button style={{backgroundColor: '#1E90FF'}}>
              Responder
             </G.Button>
             <G.Button style={{backgroundColor: '#E95839', width: '130px', marginRight: '40px'}}>
              Encerrar Chamado
             </G.Button>           
            <G.IconButton style={{backgroundColor: '#777'}}><AddCircleIcon/></G.IconButton>
            <G.IconButton onClick={() => CloseModalClick() } style={{backgroundColor: '#51B476'}}><CloseIcon /></G.IconButton>
          </PageHeader>
          <G.Body style={{flexDirection: 'row', justifyContent: 'space-around', width: '98%'}}>
            {showReply &&
            <G.Box style={{border: '1px solid #ccc', width: '75%', justifyContent: 'start', alignItems: 'center', padding: '0px'}} >
                  <G.BoxHeader style={{height: '40px', backgroundColor: '#f9f9f9', width: '97%', borderRadius: '5px', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <ArticleIcon style={{fontSize: '30px', marginRight: '10px'}}/>
                      <G.DistacInfoText style={{fontSize: '18px'}}>
                        Artigos 
                      </G.DistacInfoText>
                      <div style={{marginLeft: '10px', backgroundColor: '#ffb400', padding: '7px', fontWeight: 'bold', borderRadius: '5px'}}>{filteredArticles.length}</div>       
                    </div>
                    <Search>
                     <G.SearchInput ref={ref} onChange={(event) => handleSearchArticle(event.target.value)}/>
                      <CloseIcon onClick={() => handleSearchArticle('reset()')}  style={{marginLeft: '-30px', fontSize: '18px', cursor: 'pointer'}}/>
                    </Search>
                  </G.BoxHeader>
                  <G.BoxBodyScroll style={{height: '390px', justifyContent: 'center', width: '98%'}}>
                   {filteredArticles.map((item, key)=>(
                    <G.Card key={key} style={{width: '94%', marginBottom: '20px', minHeight: '30px', boxShadow: 'none', border: '1px solid #ddd', padding: '10px',
                                             borderLeft: item.article_sender_type_id  === 1 ? '4px solid #333' : '4px solid #ffb400', borderRadius: '0px 5px 5px 0px'  }}>
                     <G.CardHeader style={{height: '40px', justifyContent: 'space-between', backgroundColor: '#fff', border: 'none', marginBottom: '5px'}}>
                      <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '450px'}}>
                        <div style={{backgroundColor: '#333', width: '20px', height: '20px', color: '#fff', padding: '5px', marginRight: '5px', justifyContent: 'center',
                                     alignItems: 'center', display: 'flex', fontWeight: 'bold', borderRadius: '5px'}}>
                          {key+1}
                        </div>
                        <div>
                        <G.Text style={{borderBottom: '1px solid #333', padding: '3px 0px 3px 0px'}}>
                          {item.a_from.replace(/ *\<[^)]*\> */g, '')}
                        </G.Text>
                        <G.DistacInfoText style={{padding: '3px 0px 0px 0px'}}>
                          {item.a_subject}
                        </G.DistacInfoText>
                        </div>
                      </div>
                      <G.OptionsArea style={{backgroundColor: '#1E90FF', color: '#fff', padding: '7px 10px', fontWeight: 'bold', borderRadius: '5px'}}>
                        {moment(item.create_time).format("DD-MM-YYYY HH:mm")} 
                      </G.OptionsArea>
                     </G.CardHeader>
                      <G.CardBody style={{flexDirection: 'column', color: '#555'}} dangerouslySetInnerHTML={{ __html: item.a_body.replace(/\n/g, "<br/>") }}>
                      </G.CardBody>
                      {filteredArticles[key].files.map((attachment, index)=>(
                      <div key={index}>  
                      {attachment.filename &&
                      <G.CardFooter   style={{alignItems: 'center', backgroundColor: '#fff', justifyContent: 'flex-end', width: '95%', padding: '5px', margin: '0px 5px', borderTop: 'none'  }}>
                          <div style={{backgroundColor:'#34495e', width: 'auto' , color: '#fff', fontWeight: 'bold' , display: 'flex', borderRadius: '5px', padding: '5px 20px',
                                       height: '20px', boxShadow: '0px 2px 2px 1px #ccc', justifyContent: 'center', alignItems: 'center'}}>
                            {attachment.filename.length > 50 ? attachment.filename.substring(0,50)+' ...' : attachment.filename}
                          </div>
                          <G.Button style={{backgroundColor:'#2E8B57', width: '30px', marginLeft: '1px'}} onClick={() => Download(attachment.attachment_id, attachment.filename, attachment.content_type)}>
                            <FileDownloadIcon/>
                          </G.Button>
                      </G.CardFooter>
                    }
                    </div> 
                    ))}
                  </G.Card>
                    )).reverse()
                  }
              </G.BoxBodyScroll>
            </G.Box>
            }
            <G.Box style={{border: '1px solid #ccc', width: '75%', justifyContent: 'start', alignItems: 'center', padding: '0px'}} >
              <G.BoxBody>
                <ReactQuill/>
              </G.BoxBody>
              <G.BoxFooter style={{width: '98%'}}>
              </G.BoxFooter>
            </G.Box>
            <G.Box style={{ }}>
              <div style={{}}>
                <G.CardBodyText style={{ justifyContent: 'flex-start', fontSize: '12px', backgroundColor: '#F9F9F9', borderBottom: '4px solid #BFDBF5' , width: '195px'}}>
                  <strong>
                    { ticket.state}
                  </strong>
								</G.CardBodyText>
								<G.CardBodyText style={{ justifyContent: 'flex-start', fontSize: '12px', backgroundColor: '#F9F9F9', width: '195px'}}>
                  <G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '28px', boxShadow: '0px 1px 3px 1px #bbb', marginRight: '5px'}} src={ticket.customer_photo } />    
                  <strong>
                    { ticket.customer}
                  </strong>
								</G.CardBodyText>
                <G.CardBodyText style={{ justifyContent: 'flex-start',  backgroundColor: '#F9F9F9',  width: '195px', padding: '6px 8px' }}>
									 <NoteAddIcon style={{  fontSize: '27px', marginRight: '10px', color: '#333' }}/> 
                   {moment(ticket.create_time).format("DD-MM-YYYY HH:mm")}
								</G.CardBodyText>
                <div style={{flexDirection: 'row', width: '220px', display: 'flex', height: '40px', justifyContent: 'space-between'}}>
								<G.CardBodyText style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '80px' }}>
									<BusinessIcon style={{ marginRight: '10px', fontSize: '26px' }}/>
									{ticket.customer_id}
								</G.CardBodyText>
                <G.CardBodyText style={{ justifyContent: 'start', padding: '6px 8px', width: '100px' }}>
                <G.InfoText style={{backgroundColor: '#444', color: '#fff', padding: '4px 5px', borderRadius: '5px', fontWeight: 'bold', marginRight: '10px'}}>SLA</G.InfoText>
									{ticket.sla}
								</G.CardBodyText>
                </div>  
								<G.CardBodyText style={{ justifyContent: 'start', padding: '6px 8px', width: '195px' }}>
                <TypeSpecimenIcon style={{  fontSize: '26px', marginRight: '10px' }} /> 
									{ticket.queue}
								</G.CardBodyText>      
								<G.CardBodyText style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '200px' }}>
                  <G.InfoText style={{backgroundColor: '#444', color: '#fff', padding: '4px 5px', borderRadius: '5px', fontWeight: 'bold', marginRight: '10px', marginLeft: '2px'}}>TP</G.InfoText>
									{ticket.type }
									</G.CardBodyText>
                  <G.CardBodyText style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '200px' }}>
                  <MiscellaneousServicesIcon style={{ marginRight: '10px', fontSize: '26px' }} /> 
									{ticket.service }
									</G.CardBodyText>
                  </div>
                  <div style={{border: '1px solid #ccc', padding: '10px', borderRadius: '5px', marginTop: '7px'}}>
                  <G.CardBodyText style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '200px' }}>
                    <G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '28px', boxShadow: '0px 1px 3px 1px #bbb', marginRight: '5px'}} src={ticket.owner_photo } />    
				  					{ticket.owner }
									</G.CardBodyText>
                  <G.CardBodyText style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '200px' }}>
                    <G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '28px', boxShadow: '0px 1px 3px 1px #bbb', marginRight: '5px'}} src={ticket.resp_photo } />    
                    {ticket.responsible }
									</G.CardBodyText>
				      </div>
            </G.Box>
          </G.Body>
          </M.ModalContent>
       </M.ModalContainer>
      }    
      
     

    </G.Container>
  );
}