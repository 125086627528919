
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export const menuItems = [
    {
      icon: <DashboardIcon style={{fontSize: 20}} />,
      title: 'Dashboard',
      path: '/dashboard'
    },
    {
      icon: <SettingsIcon style={{ fontSize: 20}} />,
      title: 'Chamados',
      path: '/Tickets'
    },
    {
      icon: <SettingsIcon style={{ fontSize: 20}} />,
      title: 'Calendário',
      path: '/Page01'
    },
    {
      icon: <AccountBalanceIcon style={{ fontSize: 20}} />,
      title: 'Cadastros',
      subItems: [
        {
          title: 'Minha Empresa',
          path: '/MyCompany'
        },
        {
          title: 'Tipos de Serviço', 
          path: '/ServiceTypes'
        },
        {
          title: 'Serviços',
          path: '/Services'
        },
        {
          title: 'Fornecedores',
          path: '/Suppliers'
        },
        {
          title: 'Funcionários',
          path: '/Employees'
        },
        {
          title: 'Clientes',
          path: '/Customers'
        },
        {
          title: 'Outros', 
          path: '/Others'
        }
      ]
    },
    {
      icon: <AccountBalanceIcon style={{ fontSize: 20}} />,
      title: 'Financeiros',
      subItems: [
        {
          title: 'Dashboard',
          path: '/financialDashboard'
        },
        {
          title: 'Propostas',
          path: '/proposal'
        },
        {
          title: 'Recebimentos',
          path: '/receipts'  
        },
        {
          title: 'Pagamentos',
          path: '/payments'
        },
        {
          title: 'Transferências',
          path: '/accountsTransfer'
        },
        {
          title: 'Categ. Recebimentos',
          path: '/receiptCategories'
        },
        {
          title: 'Categ. Pagamentos',
          path: '/paymentCategories'
        },
        {
          title: 'Bancos',
          path: '/banks'
        },
        {
          title: 'Contas',
          path: '/bankAccounts'
        }
      ]
    },
    {
      icon: <IntegrationInstructionsIcon style={{ fontSize: 20}} />,
      title: 'Outros',
      subItems: [
        {
          title: 'Integração',
          path: '/integration'
        }
      ]
    },
  ];
  
  export const optionsItems = [
    {
      icon: <PersonIcon style={{fontSize: 16}} />,
      title: 'Perfil',
      path: '/perfil'
    },
    {
      icon: <SettingsIcon style={{ fontSize: 16}} />,
      title: 'Ajuda',
      path: '/help'
    },
    {
      icon: <AccountBalanceIcon style={{ fontSize: 16}} />,
      title: 'Configurações',
      subItems: [
        {
          title: 'Clientes',
          path: '/Customers'
        },
        {
          title: 'Faturas', 
          path: '/financial/invoices'
        }
      ]
    },
    {
      icon: <ExitToAppIcon style={{ fontSize: 16}} />,
      title: 'Sair',
      path: '/Login'
    },
  ];

