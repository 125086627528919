import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import moment from 'moment';


import * as G from '../../styles/global'
import * as M from '../../styles/modal'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';  
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';


const CalendarContainer = styled.div`
  margin-top: 15px;
  // width: 90%;
  min-width: 90%;
  max-width: 90%;
  // max-width: 1300px;
  // margin: 0 auto;
  // font-family: Arial, sans-serif;
  // justify-content: center;
  // display: flex;
  // flex-direction: column;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const ViewSelector = styled.div`
  display: flex;
  gap: 10px;
`;

const ViewButton = styled.button`
  padding: 5px 10px;
  background-color: ${props => props.active ? '#007bff' : '#f8f9fa'};
  color: ${props => props.active ? 'white' : 'black'};
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.view === 'month' ? 'repeat(7, 1fr)' : 'repeat(3, 1fr)'};
  gap: 10px;
  width: 100vw;
  
`;

const DayCell = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  height: ${props => props.view === 'month' ? '120px' : '150px'};
  background-color: ${props => props.isToday ? '#f7fff2' : '#f9f9f9'};
  overflow-y: none;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1px;  
    background-color: #ccc;
  }
  &:hover {
  background: #eee;
  }
`;

const HourCell = styled.div`
  border-bottom: 1px solid #eee;
  padding: 5px;
  height: 60px;
`;

const Event = styled.div`
  background-color: rgba(52, 52, 52, 0.1);
  color: #000;
  max-width: 95%;
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 2px;
  cursor: pointer;
  margin: 10px 10px;
  border-left: 3px solid #4CAF50;
  flex-direction: row;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  // align-items: center;
  gap: 10px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
`;





const Calendar = () => {

  const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'reset' });

  const [view, setView] = useState('week');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [calendar, setCalendar] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState();
  const [selectedEventCalendar, setSelectedEventCalendar] = useState();
  const [modalType, setModalType] = useState(null);

  const { state: user } = useContext(UserContext)

  const handleNext = () => {
      setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1))); 
  };

  const handlePrevious = () => {
      setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1))); 
  };

  const renderCalendar = () => {
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate() ;
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const daysArray = [];

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
      const isToday = date.toDateString() === new Date().toDateString();
      const dayEvents = events.filter(event => new Date(event.start_time).toDateString() === date.toDateString());

      daysArray.push(
        <DayCell key={i} isToday={isToday} view={view}>
          <div style={{ width: '95%', padding: '2px 5px', color: '#999', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ alignItems: 'center', display: 'flex', gap: '10px'}}>
           <TodayOutlinedIcon />  
           {date.toLocaleDateString('pt-BR', { weekday: 'short', day: 'numeric' })}
          </div>  
          {dayEvents.length > 0 && (
            <div style={{backgroundColor: '#ffb400', color: '#000', width: '10px', borderRadius: '5px', padding: '2px 5px', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
              {dayEvents.length}
           </div>
          )}
          </div>
          {dayEvents.map((event, index) => (
            <Event style={{ flexDirection: 'column', borderLeft: `3px solid ${event.color}`}} key={index} onClick={() => setModalType(2) + handleEventClick(event)}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ backgroundColor: '#333', color: '#fff', maxWidth: '40px', padding: '2px 5px', alignItems: 'center', display: 'flex', justifyContent: 'center', maxHeight: '15px', minHeight: '15px' }}>
                {new Date(event.start_time).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                </div>
                <G.Image style={{ borderRadius: '50%', height: '24px', backgroundColor: '#bfdbf5', width: '24px'}} src={event.photo } />    
              </div>
              {event.title}
            </Event>
          ))}
        </DayCell>
      );
    }

    return daysArray;
  };

  const renderWeek = () => {
    const weekStart = new Date(currentDate);
    weekStart.setDate(currentDate.getDate() - currentDate.getDay());
    const weekArray = [];

    for (let i = 1; i < 8; i++) {
      const date = new Date(weekStart);
      date.setDate(weekStart.getDate() + i);
      const isToday = date.toDateString() === new Date().toDateString();
      const dayEvents = events.filter(event => new Date(event.start_time).toDateString() === date.toDateString());
      weekArray.push(
        <DayCell key={i} isToday={isToday} view={view}>
          <div style={{ borderLeft: '2px solid #555', width: '98%', padding: '2px 5px', alignItems: 'center', display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ alignItems: 'center', display: 'flex', gap: '10px'}}>
           <TodayOutlinedIcon />  
           {date.toLocaleDateString('pt-BR', { weekday: 'short', day: 'numeric' })}
          </div>  
            {dayEvents.length > 0 && (
            <div style={{backgroundColor: '#ffb400', color: '#000', width: '10px',borderRadius: '5px', padding: '2px 5px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              {dayEvents.length}
           </div>
            )}
          </div>
          {dayEvents.map((event, index) => (
            <Event key={index} style={{ borderLeft: `3px solid ${event.color}`}} onClick={() => setModalType(2) + handleEventClick(event)}>
            <div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
              <div style={{ backgroundColor: '#333', color: '#fff', maxWidth: '40px', padding: '2px 5px', alignItems: 'center', display: 'flex', justifyContent: 'center', maxHeight: '25px', minHeight: '25px' }}>
                {new Date(event.start_time).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })} 
              </div>
              {event.title}
            </div>
            <G.Image style={{ borderRadius: '50%', height: '34px', backgroundColor: '#bfdbf5', width: '34px'}} src={event.photo } />    
            </Event>
          ))}
        </DayCell>
      );
    }

    return weekArray;
  };

  const renderDay = () => {
    const hourArray = [];

    for (let i = 0; i < 24; i++) {
      const hourEvents = events.filter(event => {
        const eventDate = new Date(event.date);
        return eventDate.toDateString() === currentDate.toDateString() && eventDate.getHours() === i;
      });

      hourArray.push(
        <HourCell key={i}>
          <div>{`${i}:00`}</div>
          {hourEvents.map((event, index) => (
            <Event key={index} onClick={() => handleEventClick(event)}>{event.title}</Event>
          ))}
        </HourCell>   
      );
    }

    return hourArray;
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event)
    setShowModal(true);
  };

  const handleAddEvent = () => {
    setSelectedEvent(null);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    reset()
    setShowModal(false);
  };

  const onSubmit = async (data) => {
    console.log(data)
    switch (modalType) {
      case 1:
        const userAdd = ({
          create_by: user.id,
          change_by: user.id
        })
        const add = ({...data, ...userAdd})
        let res1 = await Api.postCalendarAppointments(add)
        console.log("novo", res1)
      break;

      case 2:
        const userUpd = ({
          id: selectedEvent.id,
          change_by: user.id
        })
        const upd = ({...data, ...userUpd})
        let res2 = await Api.postCalendarAppointments(upd)
        console.log("atualizado", res2)
      break;

      default:
      break;
    }
    reset()
    getCalendar()
    setShowModal(false);
  };

  // const handleDeleteEvent = () => {
  //   setEvents(events.filter(e => e.id !== selectedEvent.id));
  //   setShowModal(false);
  // };

  const handleCalendarChange = (e) => {
    setSelectedCalendar(e)
    getCalendarAppointments(e)
  }

  const getCalendar = async () => {
    let res = await Api.getCalendar()
    setCalendar(res)
    setSelectedCalendar('999')
    getCalendarAppointments('999')
  }

  const getCalendarAppointments = async (id) => {
    let res = await Api.getCalendarAppointments(id)
    console.log(id)
    setEvents(res)
  }

  useEffect(() => {
    getCalendar()
  }, [])

  console.log();


  return (
  <G.Container>
    <G.Header style={{ alignItems: 'center', display: 'flex' }}>
    <G.HeaderTitleArea>
      <EventAvailableOutlinedIcon style={{ fontSize: '32px', margin: '0px 0px 0px 0px' }}/>
      <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 8px', border: '0.04rem solid #333', borderRadius: '4px' }} />
      <h2>Calendario</h2>
      <div style={{marginLeft: '10px', backgroundColor: '#333', padding: '7px', color: '#fff', fontWeight: 'bold', borderRadius: '5px'}}>10</div>
    </G.HeaderTitleArea>
    <G.SearchArea style={{ gap: '10px', justifyContent: 'center' }}>
    <G.SwitchArea>
      <div style={{backgroundColor: selectedCalendar ? calendar[selectedCalendar]?.color : '#333', borderRadius: '5px', width: '30px', height: '20px'}}></div>
      <G.Select style={{ width: '300px' }} value={selectedCalendar} onChange={e => handleCalendarChange(e.target.value)}>
        {calendar.map((i, index) => (
          <option key={i.id} value={i.id}>{i.name}</option>
        ))}
      </G.Select>
    </G.SwitchArea>
    <G.SwitchArea style={{ gap: '5px', justifyContent: 'center' }}>
      <G.IconButton style={{ backgroundColor: '#333', border: 'none' }}  onClick={handlePrevious}><KeyboardArrowLeftIcon/></G.IconButton>
      <div style={{backgroundColor: '#ffb400', padding: '9px', color: '#000', fontWeight: 'bold', borderRadius: '5px', width: '120px', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
        {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
      </div>
      <G.IconButton style={{ backgroundColor: '#333', border: 'none' }}  onClick={handleNext}><KeyboardArrowRightIcon/></G.IconButton>
    </G.SwitchArea>
    <G.SwitchArea>
      <G.Button style={{ backgroundColor: view === 'week' ? 'green' : '#777', width: '50px' }} active={view === 'week'} onClick={() => setView('week')}>Sem</G.Button>
      <G.Button style={{ backgroundColor: view === 'month' ? 'green' : '#777', width: '50px' }} active={view === 'month'} onClick={() => setView('month')}>Mês</G.Button>
    </G.SwitchArea>
    <G.Button 
    onClick={() => setModalType(1) + handleAddEvent()}
    style={{ backgroundColor: '#333' }}
    >
    Adicionar
  </G.Button>
  </G.SearchArea>
  </G.Header>

    <CalendarContainer>
      <Header>
        <ViewSelector>

        </ViewSelector>
      </Header>
      <CalendarGrid view={view}>
        {view === 'month' && renderCalendar()}
        {view === 'week' && renderWeek()}
        {view === 'day' && renderDay()}
      </CalendarGrid>
      {showModal && (
        <M.ModalContainer>
          <M.ModalContentSmall style={{ borderLeft: '3px solid red', borderRadius: '0px 15px 15px 0px', width: '600px' }}>
          {modalType === 2 && (
            <div style={{ display: 'flex', justifyContent: 'start', width: '98%', gap: '10px', borderRadius: '5px', padding: '10px', alignItems: 'center'}}>
            <G.Image style={{ borderRadius: '50%', height: '44px', backgroundColor: '#bfdbf5', minWidth: '44px', marginRight: '-20px', zIndex: '10'}} src={selectedEvent.photo } />    
            <G.Text style={{ height: '10px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#333', color: '#fff', padding: '10px 15px', borderRadius: '5px', minWidth: '100px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>{selectedEvent.owner}</G.Text>
          </div>
            
          )}

            <M.ModalBody style={{display: 'flex', flexDirection: 'column', gap: '0px'}}>
              <G.FormInputArea  style={{backgroundColor: 'rgba(255, 180, 0, 0.2)'}}>
                <G.FormInputLabel>Título</G.FormInputLabel>
                <G.FormInput  readOnly={modalType === 2 && user.id === selectedEvent.created_by ? true : false} style={{backgroundColor: 'transparent'}} type="text" rows={5} defaultValue={modalType === 2 ? selectedEvent.title : ''} {...register("title")}   />
              </G.FormInputArea>
              <G.FormInputArea style={{backgroundColor: 'rgba(46, 139, 87, 0.2)', width: '50%'}}>
                <G.FormInputLabel>Data de Inicio</G.FormInputLabel>
                <G.FormInput style={{ backgroundColor: 'transparent', width: '70%', textAlign: 'center'}} 
                             type="datetime-local" 
                             min={moment().format("YYYY-MM-DDTHH:mm")} 
                             defaultValue={modalType === 2 ? moment(selectedEvent.start_time).format('YYYY-MM-DDTHH:mm') : moment().format("YYYY-MM-DDTHH:mm")}
                             {...register("start_time")}/>
              </G.FormInputArea>
              <G.FormInputArea >
                <G.FormInputLabel>Descrição</G.FormInputLabel>
                <G.TextArea style={{width: '100%', backgroundColor: '#fff', color: '#000', fontSize: '11px'}} type="text" rows={5} defaultValue={modalType === 2 ? selectedEvent.description : ''} {...register("description")}  />  
              </G.FormInputArea>
              <G.FormInputArea>
                <G.FormInputLabel>Calendário</G.FormInputLabel>
                 <G.Select style={{ width: '300px', backgroundColor: '#fff' }} value={!selectedEventCalendar ? selectedEvent?.calendar_id : selectedEventCalendar} 
                          {...register("calendar_id")} onChange={(e) => setSelectedEventCalendar(e.target.value)}  >
                  {calendar.map((i, index) => (
                    <option key={i.id} value={i.id}>{i.name}</option>
                  ))}
                </G.Select>
              </G.FormInputArea>
            </M.ModalBody>
            <M.ModalFooter style={{ gap: '5px', flexDirection: 'row' }}>
              <G.Button style={{ backgroundColor: '#777' }} type="button" onClick={closeModal}>Fechar</G.Button>
              <G.Button style={{ backgroundColor: '#333' }} onClick={handleSubmit(onSubmit)}>Salvar</G.Button>
            </M.ModalFooter>
          </M.ModalContentSmall>       
        </M.ModalContainer>
      )}
    </CalendarContainer>
    </G.Container>

  );
};

export default Calendar;
